
import IForgotPasswordRequest from 'models/request/IForgotPasswordRequest';
import { useState } from 'react';

import { useCommons } from 'contexts/CommonContext/useCommons';
import { useUsers } from 'contexts/UsersContext/useUsers';
import { TUserSignIn } from 'models/types/TUserSignIn';
import { useForms } from 'shared/hooks/useForms';
import FIELDS_INPUTS_SETTINGS from 'static/FIELDS_INPUTS_SETTINGS';
import { Input } from '../input/Input';
import Overlay from '../overlay/Overlay';
import './Login.scss';
import { useForm } from 'shared/hooks/useForm';

export default function Login(): JSX.Element {
    const { signIn, sendForgotPasswordRequest } = useUsers();
    const { toggleLogin, toggleRegistration, showLogin } = useCommons()
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [forgotPassword, toggleForgotPassword] = useState<boolean>(false);
    const { validateForm } = useForms();
    const [signInForm, setSignInForm, resetSignInForm] = useForm<TUserSignIn>({
        password: "",
        username: ""
    });

    const [forgotPasswordForm, setForgotPasswordForm, resetForgotPasswordForm] = useForm<IForgotPasswordRequest>({
        email: "", document: "", phone: ""
    });
    const USER_SIGN_IN_VALIDATION_FORM_MESSAGE = {
        username: "El nombre de usuario es necesario, debe estar completo y sin errores",
        password: "La contraseña del usuario es necesaria, debe estar completa y sin errores",
    }
    const USER_FORGOT_PASSWORD_VALIDATION_FORM_MESSAGE = {
        email: "El correo del usuario es necesario, debe estar completo y sin errores",
        phone: "El número de celular del usuario es necesario, debe estar completo y sin errores",
        document: "El documento del usuario es necesario, debe estar completo y sin errores",
    }
    const sign = async (e: any) => {
        e.preventDefault();
        if (validateForm<TUserSignIn>(USER_SIGN_IN_VALIDATION_FORM_MESSAGE, signInForm, setValidationMessage)) {
            let success: boolean = await signIn(signInForm);
            (success) && toggleLogin();
            resetSignInForm();
        }
    }

    const sendForgotPassword = async () => {
        if (validateForm<IForgotPasswordRequest>(USER_FORGOT_PASSWORD_VALIDATION_FORM_MESSAGE, forgotPasswordForm, setValidationMessage)) {
            let response: boolean = !await sendForgotPasswordRequest(forgotPasswordForm);
            toggleForgotPassword(response);
            toggleLogin();
            resetForgotPasswordForm();
        }
    }


    const closeLogin = () => {
        setValidationMessage("");
        toggleLogin();
    }
    return (
        showLogin ? <Overlay classname={`login_container`}>
            <section >
                {/* Background */}
                <div></div>
                {/* Container */}
                {!forgotPassword ?
                    <form className="login_box " onSubmit={(e: any) => sign(e)}>
                        <div><p>Iniciar sesión</p></div>
                        <div>
                            <Input className="type-1" showMaxLenght={true} onChange={(value: any, field: string) => signInForm && setSignInForm(value, field)} fieldInputSettings={FIELDS_INPUTS_SETTINGS.username} value={signInForm.username} />
                            <Input className="type-1" showMaxLenght={true} onChange={(value: any, field: string) => signInForm && setSignInForm(value, field)} fieldInputSettings={FIELDS_INPUTS_SETTINGS.password} value={signInForm.password} />
                        </div>
                        <div>
                            <button type='button' style={{ cursor: "pointer" }} onClick={() => {
                                toggleLogin()
                                toggleRegistration()
                            }}>Registrarse</button>
                            <button type='button' style={{ cursor: "pointer" }} onClick={() => toggleForgotPassword(true)}>¿Olvidaste tu contraseña?</button></div>
                        <div className='buttons-wrapper'>
                            <button type='submit' >Ingresar</button> <button onClick={() => closeLogin()}>Cerrar</button>
                        </div>
                        {validationMessage !== "" && <p className="validation-message">{validationMessage}</p>}
                    </form>
                    :
                    <form className="forgot_password_box show_in_above" onSubmit={() => sendForgotPassword}>
                        <div><p>Recuperar contraseña</p></div>
                        <div>
                            <Input className="type-1" showMaxLenght={false} onChange={(value: any, field: string) => forgotPasswordForm && setForgotPasswordForm(value, field)} fieldInputSettings={FIELDS_INPUTS_SETTINGS.email} value={forgotPasswordForm.email} />
                            <Input className="type-1" showMaxLenght={false} onChange={(value: any, field: string) => forgotPasswordForm && setForgotPasswordForm(value, field)} fieldInputSettings={FIELDS_INPUTS_SETTINGS.document} value={forgotPasswordForm.document} />
                            <Input className="type-1" showMaxLenght={false} onChange={(value: any, field: string) => forgotPasswordForm && setForgotPasswordForm(value, field)} fieldInputSettings={FIELDS_INPUTS_SETTINGS.phone} value={forgotPasswordForm.phone} />
                        </div>
                        <div className='buttons-wrapper'><button onClick={() => toggleForgotPassword(false)}>Cerrar</button> <button type='submit'>Recuperar</button></div>
                        {validationMessage !== "" && <p className="validation-message">{validationMessage}</p>}
                    </form>
                }
            </section>

        </Overlay> : <></>
    )
}
