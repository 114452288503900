import { useCommons } from 'contexts/CommonContext/useCommons';
import { useEffect } from 'react';
import { DotLoader } from 'react-spinners'
import './LoadingScreen.scss'

export default function LoadingScreen(): JSX.Element {

    const { loadingScreen, setLoadingScreen } = useCommons();
    useEffect(() => { }, [loadingScreen])
    return (
        loadingScreen ? <div className='loading_screen flex-row' >
            <div style={{ margin: "auto", width: "100px", height: "100px" }}>
                <DotLoader loading={loadingScreen} color={"#6046D3"} /></div>
        </div> : <></>
    )
}
