import { useCommons } from "contexts/CommonContext/useCommons";
import TEvent from "models/types/TEvent";
// import { TEventServiceResponse } from "models/responses/TEventServiceResponse";
import { TEventState } from "models/states/TEventState";
import { useReducer } from "react";



import TEventRequest from "models/request/IEventRequest";
import EventService from "services/eventsService";
import FileService from "services/fileService";
import { EventsContext } from "./EventsContext";
import { eventsReducer } from "./EventsReducer";



interface IProps {
    children: JSX.Element | JSX.Element[]
}



const INITIAL_STATE: TEventState = {
    events: [],
}




export const EventProvider = ({ children }: IProps) => {
    const [eventsState, dispatch] = useReducer(eventsReducer, INITIAL_STATE);
    const { setLoadingScreen, setScreenMessage } = useCommons();

    const fetchEvents = async (): Promise<void> => {
        const { message, payload, status } = await EventService.getEvents();
        if (status === 200) {
            setEventsToContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
    }

    const createEvent = async (eventRequest: TEventRequest, mainImage?: File, images?: File[]): Promise<void> => {
        setLoadingScreen(true);
        let [startDate, startHour] = eventRequest.event.startDate.split(" ")
        let [startYear, startMonth, startDay] = startDate.split("-");
        let [expirationDate, expirationHour] = eventRequest.event.expirationDate.split(" ")
        let [expirationYear, expirationMonth, expirationDay] = expirationDate.split("-");

        if (Number(startMonth) < Number(expirationMonth) || Number(startDay) < Number(expirationDay)) {
            const { message, payload, status } = await EventService.createByDateDifference(eventRequest);
            let messageAux = message, statusAux = status;
            if (status === 200) {
                if (mainImage || images) {
                    const response = await FileService.uploadImages(EventService.uploadEventsImages(payload, mainImage, images));
                    if ((response).status !== 200) {
                        statusAux = response.status;
                        messageAux = "Los eventos se crearon correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición de los mismos e intente subirlas nuevamente";
                    }
                }
                addEventsToContext(payload);
                setScreenMessage({ message: messageAux, status: statusAux, returnPath: "/management/events" })
            } else {
                setScreenMessage({ message, status })
            }

        } else {
            const { payload, message, status } = await EventService.createEvent(eventRequest);
            let messageAux = message, statusAux = status;
            if (status === 200) {
                if (mainImage || images) {
                    const response = await FileService.uploadImages(EventService.uploadImages(payload, mainImage, images));
                    if ((response).status !== 200) {
                        statusAux = response.status;
                        messageAux = "El evento se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente";
                    }
                }
                addEventToContext(payload);
                setScreenMessage({ message: messageAux, status: statusAux, returnPath: "/management/events" })
            } else {
                setScreenMessage({ message: message, status: status })
            }
        }
        setLoadingScreen(false);
    }
    const updateEvent = async (eventRequest: TEventRequest, mainImage?: File, images?: File[]): Promise<void> => {
        const { message, payload, status } = await EventService.updateEvent(eventRequest);
        if (status === 200) {
            const response = await FileService.uploadImages(EventService.uploadImages(payload, mainImage, images));
            if ((response).status !== 200) {
                setScreenMessage({ message: "El evento se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status })
            }
            addEventToContext(payload);
        }
        setScreenMessage({ message, status })

        setLoadingScreen(false);
    }


    const deleteEvent = async (idEvent: number): Promise<void> => {
        setLoadingScreen(true);
        const { status, message } = await EventService.deleteEvent(idEvent);
        if (status === 200) {
            deleteEventFromContext(idEvent);
        }
        setScreenMessage({ message, status });

        setLoadingScreen(false);
    }

    const deleteImage = async (id: number, image: string): Promise<TEvent | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await EventService.deleteImage(id, image);
        if (status === 200) {
            updateEventFromContext(payload);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }

    const updateAvailability = async (id: number, availability: boolean): Promise<TEvent | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await EventService.updateEventAvailability(id, availability ? "active" : "inactive");
        if (status === 200) {
            updateEventFromContext(payload);
            setLoadingScreen(false);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }

    const getEventFromByIdFromContext = (idEvent: number): TEvent | undefined => {
        return eventsState.events.find((e) => e.idEvent === idEvent);
    }

    const setEventsToContext = (value: TEvent[]): void => {
        dispatch({ type: 'setEvents', payload: value })
    }
    const addEventToContext = (value: TEvent): void => {
        dispatch({ type: 'addEvent', payload: value })
    }
    const addEventsToContext = (value: TEvent[]): void => {
        dispatch({ type: 'addEvents', payload: value })
    }
    const deleteEventFromContext = (idEvent: number): void => {
        dispatch({ type: 'deleteEvent', payload: idEvent })
    }
    const updateEventFromContext = (event: TEvent): void => {
        dispatch({ type: 'updateEvent', payload: event })
    }





    return (
        <EventsContext.Provider value={{
            deleteImage,
            updateEvent,
            createEvent,
            fetchEvents,
            deleteEvent,
            eventsState,
            setEventsToContext,
            updateEventFromContext,
            getEventFromByIdFromContext,
            updateAvailability
        }}>
            {children}
        </EventsContext.Provider>
    )

}



