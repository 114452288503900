import { httpServer } from "clients/server";
import { useUsers } from "contexts/UsersContext/useUsers";
import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "shared/components/footer/Footer";
import { Screen } from "shared/components/screen/Screen";
import { useFetchData } from "shared/hooks/useFetchData";
import ManagementLatNavbar from "views/private/management/management_latnavbar/ManagementLatNavbar";
import ManagementNavbar from "views/private/management/management_navbar/ManagementNavbar";
import ManagementScreen from "views/private/management/management_screen/ManagementScreen";
import { ManagementView } from "views/private/management/ManagementView";

import { useCommons } from './../contexts/CommonContext/useCommons';
import privateManagementRoutes from "./PrivateManagementRoutes";
import PrivateRoute from "./PrivateRoute";
import { publicRoutes } from "./PublicRoutes";
import LandingPageLoadingScreen from "shared/components/landing_loading_screen/LandingPageLoadingScreen";
import Navbar from "shared/components/navbar/Navbar";
const UserProfileViewLazy = lazy(() => import('../views/public/user_profile_view/UserProfileView'));

const LandingPageLazy = lazy(() => {
    return Promise.all([
        import("./../views/public/landing_page/LandingPage"),
        new Promise(resolve => setTimeout(resolve, 2000))
    ])
        .then(([moduleExports]) => moduleExports);
});

interface IProps {
    navbarScrollBehaviour: boolean,
}
const { path, component: Component, children } = privateManagementRoutes;
export default function AppRouter({ navbarScrollBehaviour }: IProps) {
    const {fetchData} = useFetchData();
    const { userLogged, signOut } = useUsers();
    const { setScreenMessage, setLoadingScreen
    } = useCommons();
    useEffect(() => {
        fetchData();
    },[])

    httpServer.interceptors.response.use(
        (response: any) => {
            if (response.response && (response.response.status >= 400)) {
                setLoadingScreen(true);
                setScreenMessage({ message: "Tiempo de sesión expirado", status: response.response.status });
                signOut();
                setLoadingScreen(false);
            }
            return response;
        },
        (error: any) => {
            // Aquí puedes manejar los errores de manera uniforme
            if (error.response && (error.response.status >= 400)) {
                setLoadingScreen(true);
                setScreenMessage({ message: "Tiempo de sesión expirado", status: error.response.status });
                signOut();
                setLoadingScreen(false);
            }
            return error;
        }
    );

    return (
        <BrowserRouter>
            <Suspense fallback={<LandingPageLoadingScreen />}>
                <Routes>
                    {
                        publicRoutes.map(({ path, component: Component, name }) => (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <Screen
                                        renderHeader={(subSectionProps) => (<Navbar navbarScrollBehaviour={navbarScrollBehaviour} />)}
                                        renderBody={(subSectionProps) => (<Component subSectionProps={subSectionProps} />)}
                                        renderFooter={(subSectionProps) => (<Footer subSectionProps={subSectionProps} />)}
                                    />}
                            />
                        ))
                    }
                    <Route
                        path="/perfil/"
                        element={
                            <PrivateRoute isAuthenticated={((userLogged !== undefined) && (userLogged.role !== "ADMIN")) ? true : false}>
                                <Screen
                                    renderHeader={(subSectionProps) => (<Navbar navbarScrollBehaviour={navbarScrollBehaviour} />)}
                                    renderBody={(subSectionProps) => (<UserProfileViewLazy />)}
                                    renderFooter={(subSectionProps) => (<></>)}
                                />

                            </PrivateRoute>
                        }
                    />
                    {
                        <Route
                            key={path}
                            path={path}
                            element={
                                <PrivateRoute isAuthenticated={((userLogged !== undefined) && (userLogged.role === "ADMIN")) ? true : false}>
                                    <Component />
                                </PrivateRoute>
                            }
                        >
                            {children?.map(({ component: Component, name, path }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={
                                        <PrivateRoute isAuthenticated={((userLogged !== undefined) && (userLogged.role === "ADMIN")) ? true : false}>
                                            <ManagementScreen
                                                renderHeader={(subSectionProps) => (<ManagementNavbar subSectionProps={subSectionProps} />)}
                                                renderBody={(subSectionProps) => (
                                                    <ManagementView
                                                        renderMain={() => (<Component subSectionProps={subSectionProps} />)}
                                                        renderSide={() => (<ManagementLatNavbar subSectionProps={subSectionProps} />)}
                                                    />
                                                )}
                                            ></ManagementScreen>
                                        </PrivateRoute>
                                    }
                                />
                            ))}
                        </Route>
                    }
                    <Route path="*" element={<LandingPageLazy />} />
                </Routes>

            </Suspense>
        </BrowserRouter>


    )
}
