import { TDisciplineState } from 'models/states/TDisciplineState';
import TDiscipline from 'models/types/TDiscipline';




type disciplinesAction =
    | { type: 'setDisciplines', payload: TDiscipline[] }
    | { type: 'addDiscipline', payload: TDiscipline }
    | { type: 'addDisciplines', payload: TDiscipline[] }
    | { type: 'deleteDiscipline', payload: number }
    | { type: 'setDisciplinesToContext', payload: TDiscipline[] }
    | { type: 'updateDiscipline', payload: TDiscipline }


export const disciplinesReducer = (state: TDisciplineState, action: disciplinesAction): TDisciplineState => {

    switch (action.type) {

        case 'setDisciplinesToContext':
            return {
                ...state,
                disciplines: action.payload
            }
        case 'addDiscipline':
            return {
                ...state,
                disciplines: [...state.disciplines, action.payload]
            }
        case 'addDisciplines':
            return {
                ...state,
                disciplines: state.disciplines.concat(action.payload)
            }
        case 'deleteDiscipline':

            return {
                ...state,
                disciplines: state.disciplines.filter((n: TDiscipline, i) => n.idDiscipline !== action.payload)
            }
        case 'updateDiscipline':
            let disciplines: TDiscipline[] = state.disciplines;
            for (let i = 0; i < disciplines.length; i++) {
                if (disciplines[i].idDiscipline === action.payload.idDiscipline)
                    disciplines[i] = action.payload;
            }
            return {
                ...state,
                disciplines
            }
        default:
            return state;
    }

}