import axios from 'axios';
import { ITokens } from 'models/types/TTokens';
import ENV_CONFIG from '../static/ENV_CONFIG.json';

export const httpServer = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === "DEV" || !process.env.REACT_APP_ENVIRONMENT ? ENV_CONFIG.DEV_URL : ENV_CONFIG.PROD_URL,
  headers: {
    'Content-type': 'application/json',
  }

});
httpServer.interceptors.request.use(
  config => {
    const token_aux = localStorage.getItem('tokens'); // Obtiene el token JWT del almacenamiento local
    if (token_aux !== null && config.headers) {
      const token = (JSON.parse(token_aux) as ITokens).authorizationToken;
      config.headers['Authorization'] = 'Bearer ' + token; // Agrega el token JWT como un encabezado de autorización
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);



export const httpServerMultipart = axios.create({
  baseURL: process.env.REACT_APP_API_ENV === "DEV" || !process.env.REACT_APP_API_ENV ? ENV_CONFIG.DEV_URL : ENV_CONFIG.PROD_URL,
  headers: {
    'Content-type': 'multipart/form-data',

  }

});


