import { useEffect, useState } from "react"


type TUseInputProps = {
    maxLength?:number,
    // value:string | number,
    onChange:(value:any,field:string) => void,
    validation?:RegExp,
    length?:number
}
type TUseInput = {
    onChangeInput:(keyEvent: string | number,field:string) => void,
    characters:number,
    validate:(keyEvent: string | number) => boolean,
    isValid:boolean,
    
}

const useInput = ({maxLength = 0, onChange,validation,length = 0} :TUseInputProps ):TUseInput => {
    const [characters, setCharacters] = useState<number>(length); 
    const [isValid, setValid] = useState<boolean>(true);
    useEffect(() => {},[isValid])
    const onChangeInput = (keyEvent: string | number,field:string) => {
        if(keyEvent.toString().length <= maxLength && validate(keyEvent)){
            setCharacters(keyEvent.toString().length);
            onChange(keyEvent,field);
        }
    }
    
    const validate = (keyEvent: string | number):boolean => {
        let result:boolean = validation !== undefined && validation.test(keyEvent.toString());
        setValid(result); 
        return result;
    }


    return {
        isValid,
        onChangeInput,
        characters,
        validate
    }
}




export default useInput;