import { useReducer } from "react";
import { UsersContext } from "./UsersContext";
import { UsersReducer } from './UsersReducer';

import { useCommons } from "contexts/CommonContext/useCommons";
import TProfessor from "models/types/TProfessor";
import TStudent from "models/types/TStudent";
import TUser, { default as IUser, default as User } from "models/types/TUser";


import IForgotPasswordRequest from "models/request/IForgotPasswordRequest";
import { TUserSignIn } from "models/types/TUserSignIn";


import TUsersState from "models/states/TUsersState";
import TUserLogged from "models/types/TUserLogged";
import { TUserRegistrationForm } from "models/types/TUserRegistrationForm";
import TUserRequest from "models/types/TUserRequest";
import TProfessorRequest from "models/types/requests/TProfessorRequest";
import TStudentRequest from "models/types/requests/TStudentRequest";
import FileService from "services/fileService";
import UserService from "services/userService";
import { IChangePasswordRequest } from "models/request/IChangePasswordRequest";
import NotificationService from "services/notificationService";
import IEmailRequest from "models/request/IEmailRequest";


interface IProps {
    children: JSX.Element | JSX.Element[]
}
let loggedUserStorage = localStorage.getItem('userLogged');
const INITIAL_STATE: TUsersState = {
    users: [],
    userLogged: (loggedUserStorage !== null) ? JSON.parse(loggedUserStorage) : undefined,


}


export const UsersProvider = ({ children }: IProps) => {
    const [usersState, dispatch] = useReducer(UsersReducer, INITIAL_STATE);
    const { setLoadingScreen, setScreenMessage } = useCommons();


    const fetchUsers = async (): Promise<void> => {
        const { message, payload, status } = await UserService.getUsers();
        if (status === 200) {
            setUsersToContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
    }


    const signUp = async (userRegistrationForm: TUserRegistrationForm): Promise<boolean> => {
        let response = true;
        setLoadingScreen(true);

        const signUpResponse = await UserService.signUp(userRegistrationForm);
        if (signUpResponse && signUpResponse.status !== 200) {
            response = false;
            setScreenMessage({ message: signUpResponse.message, status: signUpResponse.status });
        }

        setLoadingScreen(false);
        return response;
    }

    const deleteUser = async (id: number): Promise<void> => {
        setLoadingScreen(true);
        const { status, message } = await UserService.deleteUser(id);
        if (status === 200) {
            deleteUserByIdFromContext(id);
        }
        setScreenMessage({ message, status });
        setLoadingScreen(false);
    }



    const updateAvailability = async (id: number, availability: boolean): Promise<TUser | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await UserService.updateUserAvailability(id, availability ? "active" : "inactive");
        if (status === 200) {
            updateUserFromContext(payload);
            setLoadingScreen(false);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }


    const signIn = async (credentials: TUserSignIn): Promise<boolean> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.signIn(credentials);
        if (status === 200) {
            setUserLogged({ user: payload.user, tokens: payload.tokens });
            setLoadingScreen(false);
        } else {
            setLoadingScreen(false);
            setScreenMessage({ message, status });
            return false;
        }
        return true;
    }

    const updateUser = async (userToUpdate: TUser, profileImg?: File, curriculumImg?: File): Promise<TUser | undefined> => {
        setLoadingScreen(true);

        const { message, payload, status } = await UserService.updateUser(userToUpdate);
        if (status === 200) {
            updateUserFromContext(payload);
            if (profileImg || curriculumImg) {
                const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg, curriculumImg));
                if (response.status !== 200) {
                    setScreenMessage({ message: "El usuario se editó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
                } else {
                    setScreenMessage({ message, status });
                }
            } else {
                setScreenMessage({ message, status });
            }
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
        return payload;
    }

    const createUser = async (userRequest: TUserRequest, profileImg: File, curriculumImg?: File): Promise<void> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.createUser(userRequest);
        if (status === 200) {
            addUser(payload);
            const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg, curriculumImg));
            if (response.status === 200) {
                setScreenMessage({ message, status, returnPath: "/management/users" });
            } else {
                setScreenMessage({ message: "El usuario se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
    }

    const createProfessor = async (professorRequest: TProfessorRequest, profileImg: File, curriculumImg?: File): Promise<void> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.createProfessor(professorRequest);
        if (status === 200) {
            addUser(payload);
            const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg, curriculumImg));
            if (response.status === 200) {
                setScreenMessage({ message, status, returnPath: "/management/users" });
            } else {
                setScreenMessage({ message: "El profesor se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
    }


    const createStudent = async (studentRequest: TStudentRequest, profileImg: File, curriculumImg?: File): Promise<void> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.createStudent(studentRequest);
        if (status === 200) {
            addUser(payload);
            const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg, curriculumImg));
            if (response.status === 200) {
                setScreenMessage({ message, status, returnPath: "/management/users" });
            } else {
                setScreenMessage({ message: "El estudiante se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
    }
    const inscribeStudent = async (student: TStudent, hash: string, profileImg: File): Promise<boolean> => {
        let success = false;
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.inscribeStudent({ hash, user: student });
        if (status === 200) {
            addUser(payload);
            const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg));
            if (response.status === 200) {
                setScreenMessage({ message, status });
            } else {
                setScreenMessage({ message: "El estudiante se inscribió correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
            success = true;
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
        return success
    }

    const inscribeProfessor = async (professor: TProfessor, hash: string, profileImg: File, curriculumImg?: File): Promise<TProfessor | undefined> => {

        setLoadingScreen(true);
        const { message, payload, status } = professor.role === "PROFESSOR" ? await UserService.inscribeProfessor({ hash, user: professor }) : await UserService.inscribeDirector({ hash, user: professor });
        if (status === 200) {
            addUser(payload);
            const response = await FileService.uploadImages(UserService.uploadImages(payload, profileImg, curriculumImg));
            if (response.status === 200) {
                setScreenMessage({ message, status });
            } else {
                setScreenMessage({ message: "El profesor se inscribió correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
        return payload;
    }


    const validateActivationLink = async (hash: string): Promise<TUser | undefined> => {

        setLoadingScreen(true);
        const { message, payload, status } = await UserService.validateActivationLink(hash);
        if (status === 200) {
            setScreenMessage({ message: message, status: status });
        } else {
            setScreenMessage({ message: message, status: status, returnPath: "/" });
        }

        setLoadingScreen(false);
        return payload;
    }
    const sendActivationLink = async (email: string): Promise<void> => {
        setLoadingScreen(true);
        const { message, status } = await UserService.sendActivationLink(email);
        setScreenMessage({ message: message, status: status });
        setLoadingScreen(false);
    }

    const sendForgotPasswordRequest = async (forgotPasswordRequest: IForgotPasswordRequest): Promise<boolean> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.forgotPassword(forgotPasswordRequest);
        if (status === 200) {
            updateUser(payload);
        }
        setScreenMessage({ message: message, status: status });
        setLoadingScreen(false);
        return true;
    }
    const changePassword = async (changePasswordRequest: IChangePasswordRequest): Promise<boolean> => {
        setLoadingScreen(true);
        const { message, payload, status } = await UserService.changePassword(changePasswordRequest);
        if (status === 200) {
            updateUserLogged(payload.user);
            setUserLogged({ user: payload.user, tokens: payload.tokens });
        }
        setScreenMessage({ message: message, status: status });
        setLoadingScreen(false);
        return true;
    }


    const deleteNotification = async (idNotification: number, idUser: number): Promise<void> => {
        setLoadingScreen(true);
        const { message, payload, status } = await NotificationService.delete(idNotification, idUser);
        if (status !== 200) {
            setScreenMessage({ message: message, status: status });
        } else if (status === 200) setUserLogged({ ...usersState.userLogged, user: payload });
        setLoadingScreen(false);
    }
    const deleteUserFromContext = (user: User) => {
        dispatch({ type: 'deleteUser', payload: user })
    }

    const deleteUserByIdFromContext = async (id: number) => {
        dispatch({ type: 'deleteUserById', payload: id })
    }

    const sendContactEmail = async (emailRequest: IEmailRequest): Promise<boolean> => {
        let response = false;
        setLoadingScreen(true);
        try {
            const { message, status } = await UserService.sendContactEmail(emailRequest);
            if (status === 200) {
                response = true;
            } else {
                setScreenMessage({ message, status })
            }
        } catch (error) {
        }

        setLoadingScreen(false);
        return response;
    }

    const addUser = (user: IUser): any => {
        dispatch({ type: 'addUser', payload: user })
    }


    const getUserByIdFromContext = (idUser: number): TUser | undefined => {
        return usersState.users.find(user => user.idUser === idUser);
    }

    const getDirectors = (): TProfessor[] => {
        return usersState.users.filter(user => user.role === "DIRECTOR") as TProfessor[];
    }

    const getProfessors = (): TProfessor[] => {
        return usersState.users.filter(user => user.role === "PROFESSOR") as TProfessor[];
    }

    const getStudents = (): TStudent[] => {
        return usersState.users.filter(user => user.role === "STUDENT") as TStudent[];
    }

    const signOut = () => {
        dispatch({ type: 'signOut' })
    }
    const setUsersToContext = (users: IUser[]) => {
        dispatch({ type: 'setUsers', payload: users })
    }
    const setUserLogged = (userLogged: TUserLogged) => {
        dispatch({ type: 'setUserLogged', payload: userLogged })
    }
    const updateUserLogged = (user: IUser) => {
        dispatch({ type: 'updateUserLogged', payload: user })
    }

    const updateUserFromContext = (user: IUser): void => {
        dispatch({ type: 'updateUser', payload: user })
    }



    return (
        <UsersContext.Provider value={{
            inscribeProfessor,
            inscribeStudent,
            validateActivationLink,
            addUser,
            createUser,
            getDirectors,
            sendActivationLink,
            changePassword,
            createProfessor,
            createStudent,
            deleteUser,
            deleteUserByIdFromContext,
            deleteUserFromContext,
            fetchUsers,
            getProfessors,
            getStudents,
            getUserByIdFromContext,
            sendForgotPasswordRequest,
            setUserLogged,
            setUsersToContext,
            signIn,
            signOut,
            signUp,
            updateAvailability,
            updateUserFromContext,
            updateUser,
            usersState,
            deleteNotification,
            sendContactEmail

        }}>
            {children}
        </UsersContext.Provider>
    )

}



