import { useState } from 'react';
import './App.css';
import AppRouter from './routers/AppRouter';



function App(): JSX.Element {
  const [navbarScrollBehaviour, setNavbarScrollBehaviour] = useState<boolean>(true);
  const [lastScroll, setLastScroll] = useState<number>(0);

  const scrollBehaviour = () => {
    if (window.scrollY !== 0 && window.scrollY >= lastScroll) {
      setNavbarScrollBehaviour(false);
    } else {
      setNavbarScrollBehaviour(true);
    }
    setLastScroll(window.scrollY);
  }




  return (
    <div className='app' onWheel={() => scrollBehaviour()} onTouchStart={() => scrollBehaviour()} onTouchEnd={() => scrollBehaviour()}>
      <AppRouter navbarScrollBehaviour={navbarScrollBehaviour} />

    </div >
  );
}

export default App;
