import { FC, LazyExoticComponent, lazy } from 'react';





export type Route = {
    children?: Route[]
    component: LazyExoticComponent<FC<any>> | FC<any>,
    name: string;
    path: string;
}

const LandingPageLazy = lazy(() => {
    return Promise.all([
        import("./../views/public/landing_page/LandingPage"),
        new Promise(resolve => setTimeout(resolve, 2000))
    ])
        .then(([moduleExports]) => moduleExports);
});
const AcademyViewlazy = lazy(() => import('../views/public/academy_view/AcademyView'));
const InscriptionPageLazy = lazy(() => import('views/public/inscription_view/InscriptionView'));
const NewsViewLazy = lazy(() => import('../views/public/news_view/NewsView'));
const AcademiesViewLazy = lazy(() => import('../views/public/academies_view/AcademiesView'));
const EventsPageLazy = lazy(() => import('../views/public/events_view/EventsView'));


export const publicRoutes: Route[] = [
    {
        path: '/',
        component: LandingPageLazy,
        name: 'LandingPage'
    },
    {
        path: '/academias',
        component: AcademiesViewLazy,
        name: 'AcademiesPage'
    },
    {
        path: '/academias/:id',
        component: AcademyViewlazy,
        name: 'AcademyView'
    },
    {
        path: '/inscripcion/:hash',
        component: InscriptionPageLazy,
        name: 'InscriptionPage'
    },
    {
        path: '/noticias/:id',
        component: NewsViewLazy,
        name: 'NewsPage'
    },
    {
        path: '/eventos',
        component: EventsPageLazy,
        name: 'EventsPage'
    },
    {
        path: '/eventos/:id',
        component: EventsPageLazy,
        name: 'EventsPage'
    }
]


