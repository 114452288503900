


import { IChangePasswordRequest } from "models/request/IChangePasswordRequest";
import IEmailRequest from "models/request/IEmailRequest";
import IForgotPasswordRequest from "models/request/IForgotPasswordRequest";
import { IInscribeUserRequest } from "models/request/IInscribeUserRequest";
import IUpdateFormationRequest from "models/request/IUpdateFormationRequest";
import { IUpdatePasswordRequest } from "models/request/IUpdatePasswordRequest";
import IUpdateSocialMediaRequest from "models/request/IUpdateSocialMediaRequest";
import { TResponse } from "models/responses/TResponse";
import { TUserSignedInResponse } from "models/responses/TUserSignedInResponse";
import TProfessor from "models/types/TProfessor";
import { TSocialMedia } from "models/types/TSocialMedia";
import TStudent from "models/types/TStudent";
import { default as TUser } from "models/types/TUser";
import { TUserRegistrationForm } from "models/types/TUserRegistrationForm";
import TUserRequest from "models/types/TUserRequest";
import { TUserSignIn } from "models/types/TUserSignIn";
import TProfessorRequest from "models/types/requests/TProfessorRequest";
import TStudentRequest from "models/types/requests/TStudentRequest";
import { httpServer } from "../clients/server";
import IFormation from '../models/types/TFormation';


export default class UserService {

    private static usersController = "/users";

    static verify(): Promise<TResponse<string>> {
        return httpServer.get("/verify")
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }


    static getUsers(): Promise<TResponse<TUser[]>> {
        return httpServer.get(this.usersController + "/")
            .then(({ data }: any): TResponse<TUser[]> => data as TResponse<TUser[]>)
            .catch(({ response }: any): TResponse<TUser[]> => response.data as TResponse<TUser[]>);
    }

    static createUser(userRequest: TUserRequest): Promise<TResponse<TUser>> {
        return httpServer.post(`${this.usersController}/create`, userRequest)
            .then(({ data }: any): TResponse<TUser> => {
                return data as TResponse<TUser>;
            })
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static createProfessor(userRequest: TProfessorRequest): Promise<TResponse<TProfessor>> {
        return httpServer.post(`${this.usersController}/create/professor`, userRequest)
            .then(({ data }: any): TResponse<TProfessor> => {
                return data as TResponse<TProfessor>;
            })
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }
    static createStudent(userRequest: TStudentRequest): Promise<TResponse<TStudent>> {
        return httpServer.post(`${this.usersController}/create/student`, userRequest)
            .then(({ data }: any): TResponse<TStudent> => {
                return data as TResponse<TStudent>;
            })
            .catch(({ response }: any): TResponse<TStudent> => response.data as TResponse<TStudent>);
    }

    static uploadImages(user: TUser, profileImg?: File, curriculumImg?: File): FormData {
        let formData = new FormData();
        profileImg && formData.append("images", profileImg, user.profileImg);
        curriculumImg && formData.append("images", curriculumImg, (user as TProfessor).curriculum.curriculumImg);
        return formData;
    }
    static updateUser(user: TUser): Promise<TResponse<TUser>> {
        if (user.role === "PROFESSOR" || user.role === "DIRECTOR") {
            return this.updateProfessor(user as TProfessor)
        }
        return this.updateStudent(user as TStudent)
    }
    static updateProfessor(professor: TProfessor): Promise<TResponse<TUser>> {
        return httpServer.put(`${this.usersController}/update/professor`, professor)
            .then(({ data }: any): TResponse<TUser> => {
                return data as TResponse<TUser>;
            })
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static updateStudent(student: TStudent): Promise<TResponse<TUser>> {
        return httpServer.put(`${this.usersController}/update/student`, student)
            .then(({ data }: any): TResponse<TUser> => {
                return data as TResponse<TUser>;
            })
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static deleteUser(id: number): Promise<TResponse<boolean>> {
        return httpServer.post(`${this.usersController}/delete/${id}`)
            .then(({ data }: any): TResponse<boolean> => {
                return data as TResponse<boolean>;
            })
            .catch(({ response }: any): TResponse<boolean> => response.data as TResponse<boolean>);
    }


    static getStudents(): Promise<TResponse<TStudent[]>> {
        return httpServer.get('users/students')
            .then(({ data }: any): TResponse<TStudent[]> => data as TResponse<TStudent[]>)
            .catch(({ response }: any): TResponse<TStudent[]> => response.data as TResponse<TStudent[]>);
    }


    static getProfessors(): Promise<TResponse<TProfessor[]>> {
        return httpServer.get('users/professors')
            .then(({ data }: any): TResponse<TProfessor[]> => data as TResponse<TProfessor[]>)
            .catch(({ response }: any): TResponse<TProfessor[]> => response.data as TResponse<TProfessor[]>);
    }

    static updateDiscipline(userToUpdate: TUser): Promise<TResponse<TUser>> {
        return httpServer.put(`${this.usersController}/update`, userToUpdate)
            .then(({ data }: any): TResponse<TUser> => {
                return data as TResponse<TUser>;
            })
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }
    static deleteDiscipline(id: number): Promise<TResponse<boolean>> {
        return httpServer.post(`${this.usersController}/delete/${id}`)
            .then(({ data }: any): TResponse<boolean> => {
                return data as TResponse<boolean>;
            })
            .catch(({ response }: any): TResponse<boolean> => response.data as TResponse<boolean>);
    }

    static updateUserAvailability(id: number, changeEnableTo: string): Promise<TResponse<TUser>> {
        return httpServer.patch(`${this.usersController}/update/${id}/enable/${changeEnableTo}`)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }




    static signUp(userRegistrationForm: TUserRegistrationForm): Promise<TResponse<string>> {
        return httpServer.post('users/signup', userRegistrationForm)
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);

    }

    static signIn(user: TUserSignIn): Promise<TResponse<TUserSignedInResponse>> {
        return httpServer.post(`authenticate`, user)
            .then(({ data }: any): TResponse<TUserSignedInResponse> => {
                return data as TResponse<TUserSignedInResponse>
            })
            .catch((error): TResponse<TUserSignedInResponse> => {
                return error.data as TResponse<TUserSignedInResponse>
            });
    }

    static validateActivationLink(hash: string): Promise<TResponse<TUser>> {
        return httpServer.get(`${this.usersController}/inscription/${hash}`)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static sendActivationLink(email: string): Promise<TResponse<string>> {
        return httpServer.get(`${this.usersController}/activationlink/send/${email}`)
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }

    static inscribeStudent(studentRequest: IInscribeUserRequest): Promise<TResponse<TStudent>> {
        return httpServer.put(`${this.usersController}/inscribe/student`, studentRequest)
            .then(({ data }: any): TResponse<TStudent> => {
                return data as TResponse<TStudent>;
            })
            .catch(({ response }: any): TResponse<TStudent> => response.data as TResponse<TStudent>);
    }

    static inscribeProfessor(professorRequest: IInscribeUserRequest): Promise<TResponse<TProfessor>> {
        return httpServer.put(`${this.usersController}/inscribe/professor`, professorRequest)
            .then(({ data }: any): TResponse<TProfessor> => {
                return data as TResponse<TProfessor>;
            })
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }
    static inscribeDirector(directorRequest: IInscribeUserRequest): Promise<TResponse<TProfessor>> {
        return httpServer.put(`${this.usersController}/inscribe/director`, directorRequest)
            .then(({ data }: any): TResponse<TProfessor> => {
                return data as TResponse<TProfessor>;
            })
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }

    static deleteActivationLink(email: string): Promise<TResponse<string>> {
        return httpServer.delete(`${this.usersController}/delete_link/?email=${email}`)
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }








    static addFormations(id: number, formations: IFormation[]): Promise<TResponse<TProfessor>> {
        return httpServer.put(`${this.usersController}/add_formations/?id=${id}`, formations)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }
    static addSocialMedias(id: number, socialMedias: TSocialMedia[]): Promise<TResponse<TUser>> {
        return httpServer.put(`${this.usersController}/add_social_medias/?id=${id}`, socialMedias)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }
    static updateFormations(updateFormationRequest: IUpdateFormationRequest): Promise<TResponse<TProfessor>> {
        return httpServer.put(`${this.usersController}/update_formation`, updateFormationRequest)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }

    static updateSocialMedia(updateSocialMediaRequest: IUpdateSocialMediaRequest): Promise<TResponse<TProfessor>> {
        return httpServer.put(`${this.usersController}/update_social_media`, updateSocialMediaRequest)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }



    static updatePassword(updatePasswordRequest: IUpdatePasswordRequest) {
        return httpServer.put(`${this.usersController}/update_password`, updatePasswordRequest)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static changePassword(changePasswordRequest: IChangePasswordRequest): Promise<TResponse<TUserSignedInResponse>> {
        return httpServer.put(`${this.usersController}/change_password`, changePasswordRequest)
            .then(({ data }: any): TResponse<TUserSignedInResponse> => data as TResponse<TUserSignedInResponse>)
            .catch(({ response }: any): TResponse<TUserSignedInResponse> => response.data as TResponse<TUserSignedInResponse>);
    }
    static forgotPassword(forgotPasswordRequest: IForgotPasswordRequest) {
        return httpServer.post(`${this.usersController}/forgot_password`, forgotPasswordRequest)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

    static updateSummary(editSummaryRequest: { idUser: number, summary: string }) {
        return httpServer.put(`${this.usersController}/update_summary`, editSummaryRequest)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }



    static updateAcademy(id: number, idAcademy: number) {
        return httpServer.patch(`${this.usersController}/update_academy?id=${id}&id_academy=${idAcademy}`)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);
    }

    static removeAcademy(id: number, idAcademy: number) {
        return httpServer.patch(`${this.usersController}/remove_academy?id=${id}&id_academy=${idAcademy}`)
            .then(({ data }: any): TResponse<TProfessor> => data as TResponse<TProfessor>)
            .catch(({ response }: any): TResponse<TProfessor> => response.data as TResponse<TProfessor>);

    }



    static sendContactEmail(email: IEmailRequest) {
        return httpServer.post("/send/contact", email)
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }


}

