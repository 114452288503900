import { useContext } from 'react';
import { DisciplinesContext } from './DisciplinesContext';





export const useDisciplines = () => {

    const {
        fetchDisciplines,
        addDisciplineToContext,
        deleteDisciplineFromContext,
        updateDisciplineFromContext,
        deleteDiscipline,
        createDiscipline,
        updateDiscipline,
        getDisciplineByIdFromContext,
        setDisciplinesToContext,
        disciplinesState,
    } = useContext(DisciplinesContext);
    const { disciplines } = disciplinesState;

    return {
        fetchDisciplines,
        addDisciplineToContext,
        deleteDisciplineFromContext,
        updateDisciplineFromContext,
        deleteDiscipline,
        createDiscipline,
        updateDiscipline,
        setDisciplinesToContext,
        getDisciplineByIdFromContext,
        disciplines
    }
}


