import { FC, memo } from "react";
import { useUsers } from "contexts/UsersContext/useUsers";
import { TNotification } from "models/types/TNotification";
import Button from "shared/components/button/Button";
import Logo from "shared/components/logo/Logo";
import Notification from "shared/components/notification/Notification";
import UtilService from "../../../../services/utilService";
import './ManagementNavbar.scss';


const ManagementNavbar: FC<any> = () => {
    const { userLogged } = useUsers();
    return (
        <nav className="management-nav-bar">
            <Button to="/" className="nav-logo">
                <Logo size={70} />
            </Button>
            <ul className="management-nav-section-btn">
                <li className="nav-btn f-c">
                    <Button className="btn-second-type" to="/" >
                        <p>Inicio</p>
                        <img src={UtilService.resolveIconsUrl("home_white_24dp.svg")} alt="Icono de inicio" />
                    </Button>
                </li>
                <li className="nav-btn f-c notification-btn-wrapper">
                    <Button className="btn-second-type" >
                        <p>Notificaciones</p>
                        {userLogged && userLogged.notifications && userLogged.notifications.length > 0 ? <span className="notification-chip">{userLogged.notifications.length}</span> : <></>}
                    </Button>
                    <div>
                        <section>
                            {(userLogged && userLogged.notifications && userLogged.notifications.length > 0) && userLogged.notifications.map((n: TNotification, k: number) => (
                                <Notification key={k} notification={n} color={"var(--g-c)"} />
                            ))}
                        </section>
                    </div>

                </li>
            </ul>

        </nav>
    );
}

export default memo(ManagementNavbar);