import TNews from 'models/types/TNews';
import { TNewsState } from 'models/states/TNewsState';
import { createContext } from 'react';
import TNewsCreateRequest from 'models/request/TNewsCreateRequest';

type NewsContextType = {
  addNews: (news: TNews) => void,
  deleteNews: (news: number) => void,
  fetchNews: () => Promise<void>
  getNewsByIdFromContext: (idNews: number) => TNews | undefined,
  newsState: TNewsState,
  setNewsToContext: (news: TNews[]) => void,
  updateAvailability: (id: number, availability: boolean) => Promise<TNews | undefined>,
  updateNews: (news: TNews, mainImage?: File, bodyImage?: File, images?: File[]) => Promise<TNews | undefined>
  createNews: (newsToCreate: TNewsCreateRequest, mainImage: File, bodyImage?: File, images?: File[]) => Promise<void>,
  deleteImage: (id: number, image: string) => Promise<TNews | undefined>,
}
export const NewsContext = createContext<NewsContextType>({} as NewsContextType);