import TEvent from 'models/types/TEvent';
import { TEventState } from 'models/states/TEventState';



type eventsAction =
    | { type: 'setEvents', payload: TEvent[] }
    | { type: 'addEvent', payload: TEvent }
    | { type: 'addEvents', payload: TEvent[] }
    | { type: 'deleteEvent', payload: number }
    | { type: 'setEvent', payload: TEvent[] }
    | { type: 'updateEvent', payload: TEvent }


export const eventsReducer = (state: TEventState, action: eventsAction): TEventState => {

    switch (action.type) {

        case 'setEvents':
            return {
                ...state,
                events: action.payload
            }
        case 'addEvent':
            return {
                ...state,
                events: [...state.events, action.payload]
            }
        case 'addEvents':
            return {
                ...state,
                events: state.events.concat(action.payload)
            }
        case 'deleteEvent':

            return {
                ...state,
                events: state.events.filter((n:TEvent, i) => n.idEvent !== action.payload)
            }
        case 'updateEvent':
            let events: TEvent[] = state.events;
            for (let i = 0; i < events.length; i++) {
                if (events[i].idEvent === action.payload.idEvent)
                    events[i] = action.payload;
            }
            return {
                ...state,
                events
            }
        default:
            return state;
    }

}