import { useContext } from 'react';
import { NewsContext } from './NewsContext';




export const useNews = () => {

    const { newsState,
        fetchNews,
        addNews,
        setNewsToContext,
        deleteNews,
        updateNews,
        getNewsByIdFromContext,
        createNews,
        deleteImage,
        updateAvailability
    } = useContext(NewsContext);
    const { news } = newsState;

    return {
        deleteImage,
        fetchNews,
        news,
        addNews,
        setNewsToContext,
        deleteNews,
        updateNews,
        getNewsByIdFromContext,
        createNews,
        updateAvailability
    }
}


