import { TAcademyState } from 'models/states/TAcademyState';
import TAcademy from '../../models/types/TAcademy';


type academyAction =
    | { type: 'setAcademies', payload: TAcademy[] }
    | { type: 'addAcademy', payload: TAcademy }
    | { type: 'deleteAcademyFromContext', payload: TAcademy }
    | { type: 'deleteAcademyByIdFromContext', payload: number }
    | { type: 'selectAcademyToDelete', payload: TAcademy | undefined }
    | { type: 'updateAcademy', payload: TAcademy }



export const academyReducer = (state: TAcademyState, action: academyAction): TAcademyState => {

    switch (action.type) {
        case 'setAcademies':
            return {
                ...state,
                academies: action.payload
            }
        case 'addAcademy':
            return {
                ...state,
                academies: [...state.academies, action.payload]
            }
        case 'deleteAcademyFromContext':
            return {
                ...state,
                academies: state.academies.filter((a:TAcademy) => a !== action.payload)
            }
        case 'deleteAcademyByIdFromContext':
            return {
                ...state,
                academies: state.academies.filter((a:TAcademy) => a.idAcademy !== action.payload)
            }
        case 'selectAcademyToDelete':
            return {
                ...state,
                selectedAcademy: action.payload
            }
        case 'updateAcademy':
            let academies: TAcademy[] = state.academies;
            for (let i = 0; i < academies.length; i++) {
                if (academies[i].idAcademy === action.payload.idAcademy)
                    academies[i] = action.payload;
            }
            return {
                ...state,
                academies
            }
        default:
            return state;
    }

}