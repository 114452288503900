import { lazy } from "react";
import { Route } from "./PublicRoutes";




const ManagementAcademiesViewLazy = lazy(() => import('../views/private/management/management_academy_view/ManagementAcademiesView'));
const ManagementAcademyDetailViewLazy = lazy(() => import('../views/private/management/management_academy_view/management_academy_detail_view/ManagementAcademyDetailView'));
const ManagementAcademyCreateViewLazy = lazy(() => import('../views/private/management/management_academy_view/management_academy_create_view/ManagementAcademyCreateView'));

const ManagementAcademyRoutes: Route[] = [
    {
        path: 'academies/',
        component: ManagementAcademiesViewLazy,
        name: 'component: ManagementAcademyDetailView',
    },
    {
        path: 'academies/:id',
        component: ManagementAcademyDetailViewLazy,
        name: 'component: ManagementAcademyDetailView',
    },
    {
        path: 'academies/create',
        component: ManagementAcademyCreateViewLazy,
        name: 'component: ManagementAcademyCreateView',
    }
]

export default ManagementAcademyRoutes;