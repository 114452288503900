import { useEffect, useReducer } from "react";
import { CommonContext } from "./CommonContext";
import { CommonReducer } from './CommonReducer';


import { ICommonState } from "models/states/TCommonState";
import { TScreenMessage } from "models/types/TScreenMessage";


interface IProps {
    children: JSX.Element | JSX.Element[]
}



const INITIAL_STATE: ICommonState = {


    loadingScreen: false,
    showLogin: false,
    showContact: false,
    showRegistration: false,
    actionConfirmationPopUp: {
        action: undefined,
        message: undefined
    },

    screenMessage: {
        message: "",
        status: 0,
        returnPath: ""
    }


}



export const CommonProvider = ({ children }: IProps) => {
    const [commonState, dispatch] = useReducer(CommonReducer, INITIAL_STATE);

    useEffect(() => {

    }, [])

    const setActionConfirmationPopup = (action?: () => void, message?: string): void => {
        dispatch({ type: 'setActionConfirmationPopup', payload: { action, message } })
    }

    const setScreenMessage = (value: TScreenMessage): void => {
        dispatch({ type: 'setScreenMessage', payload: value })
    }

    const setLoadingScreen = (value: boolean) => {
        dispatch({ type: 'setLoadingScreen', payload: value })
    }



    const toggleLogin = () => {

        dispatch({type:"toggleLogin"})
    }
    const toggleContact = () => {
        dispatch({type:"toggleContact"})
    }
    const toggleRegistration = () => {
        dispatch({type:"toggleRegistration"})
    }


    return (
        <CommonContext.Provider value={{
            commonState,
            setLoadingScreen,
            setScreenMessage,
            setActionConfirmationPopup,
            toggleLogin,
            toggleContact,
            toggleRegistration,

        }}>
            {children}
        </CommonContext.Provider>
    )

}



