import TUsersState from 'models/states/TUsersState';
import { default as IUser, default as TUser } from 'models/types/TUser';
import TUserLogged from 'models/types/TUserLogged';





type usersAction =
    | { type: 'addUser', payload: IUser }
    | { type: 'setUsers', payload: IUser[] }
    | { type: 'setUserLogged', payload: TUserLogged }
    | { type: 'updateUserLogged', payload: IUser }
    | { type: 'signOut' }
    | { type: 'deleteUser', payload: IUser }
    | { type: 'deleteUserById', payload: number }
    | { type: 'updateUser', payload: IUser }
    | { type: 'setAcademyByUserId', payload: number }
// | { type: 'signUp', payload: IUserRegistrationForm }

export const UsersReducer = (state: TUsersState, action: usersAction): TUsersState => {

    switch (action.type) {
        case 'setUsers':
            return {
                ...state,
                users: action.payload
            }
        case 'addUser':

            return {
                ...state,
                users: [...state.users, action.payload]
            }
        case 'deleteUser':
            return {
                ...state,
                users: state.users.filter((u:TUser) => u !== action.payload)
            }
        case 'deleteUserById':
            return {
                ...state,
                users: state.users.filter((u:TUser) => u.idUser !== action.payload)
            }
        case 'setUserLogged':
            if (action.payload) {
                localStorage.setItem("userLogged", JSON.stringify(action.payload.user));
                action.payload.tokens && localStorage.setItem("tokens", JSON.stringify(action.payload.tokens));
                return {
                    ...state,
                    userLogged: action.payload.user
                }
            }
            return {
                ...state
            }



        case 'signOut':
            localStorage.removeItem("userLogged");
            localStorage.removeItem("tokens");
            return {
                ...state,
                userLogged: undefined
            }

        case 'updateUser':

            let users: IUser[] = state.users;
            for (let i = 0; i < users.length; i++) {
                if (users && users[i] && users[i].idUser === action.payload?.idUser)
                    users[i] = action.payload;
            }

            return {
                ...state,
                users
            }
        case 'updateUserLogged':
            // let userStorage = localStorage.getItem("userLogged");

            localStorage.setItem("userLogged", JSON.stringify(action.payload))
            // if(userStorage != null){
            //     let user = JSON.parse(userStorage);
            // }
            //  = JSON.parse();
            // for (let i = 0; i < users.length; i++) {
            //     if (users && users[i] && users[i].idUser === action.payload?.idUser)
            //         users[i] = action.payload;
            // }

            return {
                ...state,
                userLogged: action.payload
            }






        case 'setAcademyByUserId':
            return {
                ...state
                // academy: academies.filter((a,k) =>{
                //     a.professors.filter((p,i) => p.idUser === action.payload)
                //     a.students.filter((s,i) => s.idUser === action.payload)
                // }) 
            }



        default:
            return state;
    }

}