
import TEventRequest from "models/request/IEventRequest";
import { TResponse } from "models/responses/TResponse";
import TEvent from "models/types/TEvent";
import { httpServer } from "../clients/server";
export default class EventService {

    static eventsController: string = "/events"

    static getEvents(): Promise<TResponse<TEvent[]>> {
        return httpServer.get(`${this.eventsController}/`)
            .then(({ data }: any): TResponse<TEvent[]> => {
                return data as TResponse<TEvent[]>;
            })
            .catch(({ response }: any): TResponse<TEvent[]> => response.data as TResponse<TEvent[]>);
    }


    static createByDateDifference(eventRequest: TEventRequest) {
        return httpServer.post(`${this.eventsController}/create/events/by-date-difference`, eventRequest)
            .then((response): any => {
                return response.data as TResponse<TEvent[]>;
            })
            .catch(({ response }: any): TResponse<TEvent[]> => response.data as TResponse<TEvent[]>);
    }


    static createEvent(eventRequest: TEventRequest): Promise<TResponse<TEvent>> {
        return httpServer.post(`${this.eventsController}/create`, eventRequest)
            .then(({ data }: any): TResponse<TEvent> => {
                return data as TResponse<TEvent>;
            })
            .catch(({ response }: any): TResponse<TEvent> => response.data as TResponse<TEvent>);
    }

    static updateEvent(event: TEventRequest): Promise<TResponse<TEvent>> {
        return httpServer.post(`${this.eventsController}/update`, event)
            .then(({ data }: any): TResponse<TEvent> => {
                return data as TResponse<TEvent>;
            })
            .catch(({ response }: any): TResponse<TEvent> => response.data as TResponse<TEvent>);
    }
    static deleteEvent(id: number): Promise<TResponse<boolean>> {
        return httpServer.delete(`${this.eventsController}/delete/${id}`)
            .then(({ data }: any): TResponse<boolean> => {
                return data as TResponse<boolean>;
            })
            .catch(({ response }: any): TResponse<boolean> => response.data as TResponse<boolean>);
    }


    static deleteImage(id: number, image: string) {
        return httpServer.patch(`${this.eventsController}/delete/${id}/image/${image}/`)
            .then(({ data }: any): TResponse<TEvent> => data as TResponse<TEvent>)
            .catch(({ response }: any): TResponse<TEvent> => response.data as TResponse<TEvent>);
    }

    static uploadImages(event: TEvent, mainImage?: File, images?: File[]): FormData {
        let formData = new FormData();
        mainImage && formData.append("images", mainImage, event.mainImage);

        if (images && images.length > 0) {
            images.forEach((image: File, index: number) => {
                event.images && formData.append("images", image, event.images[index]);
            })
        }
        return formData;
    }

    static uploadEventsImages(events: TEvent[], mainImage?: File, images?: File[]): FormData {
        let formData = new FormData();
        events.forEach((a) => {
            mainImage && formData.append("images", mainImage, a.mainImage);
            if (images && images.length > 0) {
                images.forEach((image: File, index: number) => {
                    a.images && formData.append("images", image, a.images[index]);
                })
            }
        })
        return formData;
    }

    static updateEventAvailability(id: number, changeEnableTo: string) {
        return httpServer.patch(`${this.eventsController}/update/${id}/enable/${changeEnableTo}`)
            .then(({ data }: any): TResponse<TEvent> => data as TResponse<TEvent>)
            .catch(({ response }: any): TResponse<TEvent> => response.data as TResponse<TEvent>);
    }






}