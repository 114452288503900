import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CommonProvider } from 'contexts/CommonContext/CommonProvider';
import { EventProvider } from 'contexts/EventContext/EventsProvider';
import { UsersProvider } from 'contexts/UsersContext/UsersProvider';
import { AcademiesProvider } from 'contexts/AcademiesContext/AcademiesProvider';
import { NewsProvider } from 'contexts/NewsContext/NewsProvider';
import ActionConfirmationPopUp from 'shared/components/action_confirmation_pop_up/ActionConfirmationPopUp';
import ScreenMessage from 'shared/components/screen_message/ScreenMessage';
import RegistrationBox from 'shared/components/registration_pop_up/RegistrationBox';
import Login from 'shared/components/login/Login';
import ContactBox from 'shared/components/contact_box/ContactBox';
import LoadingScreen from 'shared/components/loading_screen/LoadingScreen';
import { DisciplineProvider } from 'contexts/DisciplinesContext/DisciplinesProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <CommonProvider>
    <EventProvider>
      <UsersProvider>
        <AcademiesProvider>
          <DisciplineProvider>
            <NewsProvider>
              <>
                <ActionConfirmationPopUp />
                <ScreenMessage />
                <Login />
                <RegistrationBox />
                <ContactBox />
                <LoadingScreen />
                <App />
              </>
            </NewsProvider>
          </DisciplineProvider>
        </AcademiesProvider>
      </UsersProvider>
    </EventProvider>
  </CommonProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
