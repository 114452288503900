import { lazy } from "react";
import { Route } from "./PublicRoutes";



const ManagemenUsersViewLazy = lazy(() => import('../views/private/management/management_users_view/ManagementUsersView'));
const ManagementUserDetailViewLazy = lazy(() => import('../views/private/management/management_users_view/management_user_detail_view/ManagementUserDetailView'));
const ManagementUserCreateViewLazy = lazy(() => import('../views/private/management/management_users_view/management_user_create_view/ManagementUserCreateView'));

export const ManagementUsersRoutes: Route[] = [
    {
        path: 'users/',
        component: ManagemenUsersViewLazy,
        name: 'component: ManagementUserDetailView',
    },
    {
        path: 'users/:id',
        component: ManagementUserDetailViewLazy,
        name: 'component: ManagementUserDetailView',
    },
    {
        path: 'users/create',
        component: ManagementUserCreateViewLazy,
        name: 'component: ManagementUserCreateView',
    }
]

export default  ManagementUsersRoutes;