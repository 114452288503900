import { ICommonState } from 'models/states/TCommonState';
import TActionConfirmationPopUpProps from 'models/types/TActionConfirmationPopUpProps';
import { TScreenMessage } from 'models/types/TScreenMessage';

type commonAction =
    | { type: 'setLoadingScreen', payload: boolean }
    | { type: 'setScreenMessage', payload: TScreenMessage }
    | { type: 'setActionConfirmationPopup', payload: TActionConfirmationPopUpProps }
    | { type: 'toggleLogin' }
    | { type: 'toggleContact' }
    | { type: 'toggleRegistration' }


export const CommonReducer = (state: ICommonState, action: commonAction): ICommonState => {

    switch (action.type) {
        case 'setActionConfirmationPopup':
            return {
                ...state,
                actionConfirmationPopUp: action.payload
            }
        case 'setScreenMessage':
            return {
                ...state,
                screenMessage: action.payload
            }
        case 'setLoadingScreen':
            return {
                ...state,
                loadingScreen: action.payload
            }

        case 'toggleLogin':
            return {
                ...state,
                showLogin: !state.showLogin
            }
        case 'toggleContact':
            return {
                ...state,
                showContact: !state.showContact
            }
        case 'toggleRegistration':
            return {
                ...state,
                showRegistration: !state.showRegistration
            }

        default:
            return state;
    }

}