import { lazy } from "react";
import { Route } from "./PublicRoutes";




const ManagemenEventtViewLazy = lazy(() => import('../views/private/management/management_events_view/ManagementEventsView'));
const ManagementEventDetailViewLazy = lazy(() => import('../views/private/management/management_events_view/management_event_detail_view/ManagementEventDetailView'));
const ManagementEventCreateViewLazy = lazy(() => import('../views/private/management/management_events_view/management_event_create_view/ManagementEventCreateView'));

const ManagementEventRoutes: Route[] = [
    {
        path: 'events/',
        component: ManagemenEventtViewLazy,
        name: 'component: ManagementEventDetailView',
    },
    {
        path: 'events/:id',
        component: ManagementEventDetailViewLazy,
        name: 'component: ManagementEventDetailView',
    },
    {
        path: 'events/create',
        component: ManagementEventCreateViewLazy,
        name: 'component: ManagementEventCreateView',
    }
]

export default ManagementEventRoutes;