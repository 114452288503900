import { useAcademies } from 'contexts/AcademiesContext/useAcademies';
import { NavLink, useLocation } from 'react-router-dom';

import { useCommons } from 'contexts/CommonContext/useCommons';
import { useUsers } from 'contexts/UsersContext/useUsers';
import { TNotification } from 'models/types/TNotification';
import { FC } from 'react';
import UtilService from 'services/utilService';
import Notification from 'shared/components/notification/Notification';
import Logo from '../logo/Logo';
import './Navbar.css';
import './Navbar_responsive.css';


interface IProps {
    navbarScrollBehaviour: boolean
}

export default function Navbar({ navbarScrollBehaviour }: IProps): JSX.Element {
    const { pathname } = useLocation();
    const { signOut, userLogged } = useUsers();
    const { academies } = useAcademies();
    const { toggleLogin } = useCommons();
    return (
        <nav className={navbarScrollBehaviour ? `navbar_container` : "navbar_container hide"} style={pathname.includes("management") || pathname.includes("inscripcion") ? { display: "none" } : {}}>
            <div className="navbar">
                <div >
                    <NavLink to="/" ><img src="../../icons/home.svg" alt="" /><span >Inicio</span>
                    </NavLink>
                    {(academies && academies.length > 0) && <NavLink to="/academias"><img src="../../icons/school.svg" alt="" /><span>Academias</span></NavLink>}
                    <NavLink to={`/eventos/`}><img src={UtilService.resolveIconsUrl("schedule.svg")} alt="" /><span>Eventos</span></NavLink>

                    {((userLogged === undefined)) ?
                        <a className='navbar_login_button' onClick={() => toggleLogin()}><img src="../../icons/login_white_24dp.svg" alt="" />
                            <span>Ingresar</span>
                        </a>
                        :
                        <div className='signOut_container' >
                            <div>
                                <img src={userLogged.role !== "ADMIN" ? UtilService.resolveUrlImg(userLogged.profileImg) : "../assets/logo.png"} alt="" />
                            </div>
                            <ul>
                                {userLogged.role !== "ADMIN" && <li><NavLink to="/perfil/"><p className='navbar_user_item'>Perfil</p><p className='navbar_user_icon'><img src="../icons/user.svg" alt="" /></p></NavLink></li>}
                                {userLogged.role !== "ADMIN" &&
                                    <li className='nav_bar_notifications'>
                                        <NotificationMenuBox notifications={userLogged.notifications} />
                                    </li>
                                }
                                {((userLogged !== undefined) && (userLogged.role === "ADMIN")) &&
                                    <li className='admin_nav_btn'>
                                        <NavLink to="/management/home"><span>Admin</span><img src="../../icons/admin_panel_settings_white_24dp.svg" alt="" /></NavLink>
                                    </li>}
                                <li onClick={() => signOut()}><p className='navbar_user_item'>Cerrar sesión</p><p className='navbar_user_icon'><img src="../icons/logout_white_24dp.svg" alt="" /></p></li>
                            </ul>
                        </div>}
                </div>
            </div>
            <div>
                <Logo size={50} />
            </div>

        </nav >

    );
}
type TNotificationMenuBoxProps = {
    notifications?: TNotification[]
}
export const NotificationMenuBox: FC<TNotificationMenuBoxProps> = ({ notifications = [] }) => {
    return (
        <div className='notifications-box'>
            <p className='navbar_user_item'>Notificaciones</p>
            <p className='navbar_user_icon not'><img src="../icons/notifications_white_24dp.svg" alt="" /></p>
            {(notifications.length > 0)
                && <span className='navbar_not_count'>{notifications.length}</span>}
            <ul>
                {(notifications && notifications.length > 0) && notifications.map((n: TNotification, k: number) => (
                    <Notification key={k} notification={n} color={"var(--g-c)"} />
                ))}

            </ul>
        </div>
    )
}