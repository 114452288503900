import { useReducer } from "react";
import { AcademiesContext } from "./AcademiesContext";
import { academyReducer } from './AcademiesReducer';

import { useCommons } from "contexts/CommonContext/useCommons";
import { TAcademyState } from "models/states/TAcademyState";
import TDeleteAcademyRequest from "models/types/requests/TDeleteAcademyRequest";
import TAcademy from "models/types/TAcademy";
import AcademyService from "services/academyService";
import FileService from "services/fileService";
import TStudent from "models/types/TStudent";
import TProfessor from "models/types/TProfessor";



interface IProps {
    children: JSX.Element | JSX.Element[]
}



const INITIAL_STATE: TAcademyState = {
    academies: [],
    disciplines: [],
    selectedAcademy: undefined
}




export const AcademiesProvider = ({ children }: IProps) => {
    const [academiesState, dispatch] = useReducer(academyReducer, INITIAL_STATE);
    const { setLoadingScreen, setScreenMessage } = useCommons();

    const fetchAcademies = async (): Promise<void> => {
        const { message, payload, status } = await AcademyService.getAllAcademies();
        if (status === 200) {
            setAcademiesToContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
    }

    const deleteAcademy = async (deleteAcademyRequest: TDeleteAcademyRequest): Promise<void> => {
        setLoadingScreen(true);
        const { status, message } = await AcademyService.deleteAcademy(deleteAcademyRequest);
        if (status === 200) {
            deleteAcademyByIdFromContext(deleteAcademyRequest.idAcademy);
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
    }


    const createAcademy = async (academyToCreate: TAcademy, logo: File, layoutImg?: File, images?: File[], returnPath?: string): Promise<boolean> => {
        setLoadingScreen(true);
        let success = false;
        academyToCreate = { ...academyToCreate, mainColor: academyToCreate.mainColor.replace("#", "") }
        const { message, payload, status } = await AcademyService.createAcademy(academyToCreate);
        if (status === 200) {
            addAcademy(payload);
            const response = await FileService.uploadImages(AcademyService.uploadImages(payload, logo, layoutImg, images));
            if (response.status === 200) {
                setScreenMessage({ message, status, returnPath: returnPath ?? "/management/academies" });
                success = true;
            } else {
                setScreenMessage({ message: "La academia se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición de la misma e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
        return success;
    }
    const inscribeAcademy = async (academyToCreate: TAcademy, idDirector: number, hash: string, logo: File, layoutImg?: File, images?: File[], returnPath?: string): Promise<boolean> => {
        setLoadingScreen(true);
        let success = false;
        academyToCreate = { ...academyToCreate, mainColor: academyToCreate.mainColor.replace("#", "") }
        const { message, payload, status } = await AcademyService.inscribe({ hash, academy: academyToCreate, idDirector });
        if (status === 200) {
            const response = await FileService.uploadImages(AcademyService.uploadImages(payload, logo, layoutImg, images));
            if (response.status === 200) {
                setScreenMessage({ message, status });
                success = true;
            } else {
                setScreenMessage({ message: "La academia se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición de la misma e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
        return success;
    }

    const updateAcademy = async (academyToUpdate: TAcademy, logo?: File, layoutImg?: File, images?: File[]): Promise<TAcademy | undefined> => {
        setLoadingScreen(true);
        academyToUpdate = { ...academyToUpdate, mainColor: academyToUpdate.mainColor.replace("#", "") }
        const { message, payload, status } = await AcademyService.updateAcademy(academyToUpdate)
        if (status === 200) {
            if (logo || layoutImg || images) {
                const response = await FileService.uploadImages(AcademyService.uploadImages(payload, logo, layoutImg, images));
                if (response.status !== 200) {
                    setScreenMessage({ message: "La academia se editó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición de la misma e intente subirlas nuevamente", status: response.status });
                } else {
                    setScreenMessage({ message, status });
                }
            } else {
                setScreenMessage({ message, status });
            }
            updateAcademyFromContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
        return payload;
    }

    const updateAvailability = async (id: number, availability: boolean): Promise<TAcademy | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await AcademyService.updateAcademyAvailability(id, availability ? "active" : "inactive");
        if (status === 200) {
            updateAcademyFromContext(payload);
            setLoadingScreen(false);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }




    const getAcademyByIdFromContext = (idAcademy: number): TAcademy | undefined => {
        return academiesState.academies.find(academy => academy.idAcademy === idAcademy);
    }

    const deleteImage = async (id: number, image: string): Promise<TAcademy | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await AcademyService.deleteImage(id, image);
        if (status === 200) {
            updateAcademyFromContext(payload);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }

    const addMembersByDirector = async (idDirector: number, idAcademy: number, professorsToAdd: TProfessor[], studentsToAdd: TStudent[]) :Promise<boolean> => {
        let success = true;
        setLoadingScreen(true);
        const { payload, status, message } = await AcademyService.addMembersByDirector(idDirector, idAcademy, professorsToAdd, studentsToAdd);
        if (payload && status === 200) {
            updateAcademyFromContext(payload);
        } else {
            success = false;
        }
        setScreenMessage({ message, status });
        setLoadingScreen(false);
        return success;
    }







    const deleteAcademyByIdFromContext = (value: number): void => {
        dispatch({ type: 'deleteAcademyByIdFromContext', payload: value })
    }



    const setAcademiesToContext = (academies: TAcademy[]): void => {
        dispatch({ type: 'setAcademies', payload: academies })
    }
    const addAcademy = (academy: TAcademy): void => {
        dispatch({ type: 'addAcademy', payload: academy })
    }

    const selectAcademyToDelete = (academy: TAcademy | undefined): void => {
        dispatch({ type: 'selectAcademyToDelete', payload: academy })
    }
    const updateAcademyFromContext = (academy: TAcademy): void => {
        dispatch({ type: 'updateAcademy', payload: academy })
    }





    return (
        <AcademiesContext.Provider value={{
            deleteImage,
            updateAvailability,
            academiesState,
            fetchAcademies,
            addAcademy,
            createAcademy,
            selectAcademyToDelete,
            setAcademiesToContext,
            updateAcademy,
            getAcademyByIdFromContext,
            deleteAcademy,
            inscribeAcademy,
            addMembersByDirector,
        }}>
            {children}
        </AcademiesContext.Provider>
    )

}



