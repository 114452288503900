
import { TResponse } from 'models/responses/TResponse';
import TNews from 'models/types/TNews';
import { httpServer } from "../clients/server";
import TNewsCreateRequest from 'models/request/TNewsCreateRequest';

export default class NewsService {

    private static newsController = "/news";
    static getNews(): Promise<TResponse<TNews[]>> {
        return httpServer.get('/news/')
            .then(({ data }: any): TResponse<TNews[]> => {
                return data as TResponse<TNews[]>;
            })
            .catch(({ response }: any): TResponse<TNews[]> => response.data as TResponse<TNews[]>);
    }

    static createNews(news: TNewsCreateRequest): Promise<TResponse<TNews>> {
        return httpServer.post('/news/create', news)
            .then(({ data }: any): TResponse<TNews> => {
                return data as TResponse<TNews>;
            })
            .catch(({ response }: any): TResponse<TNews> => response.data as TResponse<TNews>);
    }

    static updateNews(news: TNews): Promise<TResponse<TNews>> {
        return httpServer.put('/news/update', news)
            .then(({ data }: any): TResponse<TNews> => {
                return data as TResponse<TNews>;
            })
            .catch(({ response }: any): TResponse<TNews> => response.data as TResponse<TNews>);
    }
    static deleteNews(id: number): Promise<TResponse<boolean>> {
        return httpServer.delete(`/news/delete/${id}`)
            .then(({ data }: any): TResponse<boolean> => {
                return data as TResponse<boolean>;
            })
            .catch(({ response }: any): TResponse<boolean> => response.data as TResponse<boolean>);
    }

    static updateNewsAvailability(id: number, changeEnableTo: string): Promise<TResponse<TNews>> {
        return httpServer.patch(`${this.newsController}/update/${id}/enable/${changeEnableTo}`)
            .then(({ data }: any): TResponse<TNews> => data as TResponse<TNews>)
            .catch(({ response }: any): TResponse<TNews> => response.data as TResponse<TNews>);
    }

    static uploadImages(news: TNews, mainImage?: File, bodyImage?: File, images?: File[]): FormData {
        let formData = new FormData();
        mainImage && formData.append("images", mainImage, news.mainImage);
        bodyImage && formData.append("images", bodyImage, news.bodyImage);
        if (images && images.length > 0) {
            images.forEach((image: File, index: number) => {
                formData.append("images", image, news.images[index]);

            })
        }
        return formData;
    }

    static deleteImage(id: number, image: string) {
        return httpServer.patch(`/news/delete/${id}/image/${image}/`)
            .then(({ data }: any): TResponse<TNews> => data as TResponse<TNews>)
            .catch(({ response }: any): TResponse<TNews> => response.data as TResponse<TNews>);
    }

}


