import { useCommons } from "contexts/CommonContext/useCommons";
import TNewsCreateRequest from "models/request/TNewsCreateRequest";
import { TNewsState } from "models/states/TNewsState";
import TNews from "models/types/TNews";
import { useReducer } from "react";
import FileService from "services/fileService";
import NewsService from "services/newsService";

import { NewsContext } from "./NewsContext";
import { newsReducer } from './NewsReducer';




interface IProps {
    children: JSX.Element | JSX.Element[]
}



const INITIAL_STATE: TNewsState = {
    news: []
}




export const NewsProvider = ({ children }: IProps) => {
    const [newsState, dispatch] = useReducer(newsReducer, INITIAL_STATE);
    const { setLoadingScreen, setScreenMessage } = useCommons();

    const fetchNews = async (): Promise<void> => {
        const { message, payload, status } = await NewsService.getNews();
        if (status === 200) {
            setNewsToContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
    }

    const deleteImage = async (id: number, image: string): Promise<TNews | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await NewsService.deleteImage(id, image);
        if (status === 200) {
            updateNewsFromContext(payload);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }



    const updateNews = async (newsToUpdate: TNews, mainImage?: File, bodyImage?: File, images?: File[]): Promise<TNews | undefined> => {
        setLoadingScreen(true);
        newsToUpdate = { ...newsToUpdate, mainColor: newsToUpdate.mainColor.replace("#", "") }
        const { message, payload, status } = await NewsService.updateNews(newsToUpdate)
        if (status === 200) {
            if (mainImage || bodyImage || images) {
                const response = await FileService.uploadImages(NewsService.uploadImages(payload, mainImage, bodyImage, images));
                if (response.status !== 200) {
                    setScreenMessage({ message: "La noticia se editó correctamente, pero hubo un problema al subir las imágenes. Diríjase hacia su edición y inténtelo de nuevo", status: response.status });
                }
                else {
                    setScreenMessage({ message, status });
                }
            } else {
                setScreenMessage({ message, status });
            }
            updateNewsFromContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);

        return payload;
    }


    const deleteNews = async (id: number): Promise<void> => {
        setLoadingScreen(true);
        const {status, message } = await NewsService.deleteNews(id);
        if (status === 200) {
            deleteNewsFromContext(id);
        }
        setScreenMessage({ message, status });
        setLoadingScreen(false);
    }

    const updateAvailability = async (id: number, availability: boolean): Promise<TNews | undefined> => {
        setLoadingScreen(true);
        const { payload, status, message } = await NewsService.updateNewsAvailability(id, availability ? "active" : "inactive");
        if (status === 200) {
            updateNewsFromContext(payload);
            setLoadingScreen(false);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }

    const createNews = async (newsToCreate: TNewsCreateRequest, mainImage: File, bodyImage?: File, images?: File[]): Promise<void> => {

        newsToCreate = { ...newsToCreate, mainColor: newsToCreate.mainColor.replace("#", "") }
        setLoadingScreen(true);
        const { message, payload, status } = await NewsService.createNews(newsToCreate);
        if (status === 200) {
            addNews(payload);
            const response = await FileService.uploadImages(NewsService.uploadImages(payload, mainImage, bodyImage, images));
            if (response.status === 200) {
                setScreenMessage({ message, status, returnPath: "/management/news" });
            } else {
                setScreenMessage({ message: "La noticia se creó correctamente, pero sus imágenes no lo hicieron. Diríjase a la edición del mismo e intente subirlas nuevamente", status: response.status });
            }
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
    }


    const addNews = (value: TNews): void => {
        dispatch({ type: 'addNews', payload: value })
    }
    const deleteNewsFromContext = (idNews: number): void => {
        dispatch({ type: 'deleteNews', payload: idNews })
    }
    const updateNewsFromContext = (news: TNews): void => {
        dispatch({ type: 'updateNews', payload: news })
    }
    const setNewsToContext = (value: TNews[]): void => {
        dispatch({ type: 'setNews', payload: value })
    }

    const getNewsByIdFromContext = (idNews: number): TNews | undefined => {
        return newsState.news.find(news => news.idNews === idNews);
    }


    return (
        <NewsContext.Provider value={{
            updateAvailability,
            fetchNews,
            newsState,
            addNews,
            setNewsToContext,
            updateNews,
            deleteNews,
            getNewsByIdFromContext,
            createNews,
            deleteImage
        }}>
            {children}
        </NewsContext.Provider>
    )

}



