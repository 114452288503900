import { FC, useEffect, useState } from "react";
import './ScreenMessage.scss';

import UtilService from "../../../services/utilService";
import { TScreenMessage } from "models/types/TScreenMessage";
import { useCommons } from "contexts/CommonContext/useCommons";
import Button from "../button/Button";
import Overlay from "../overlay/Overlay";


type TScreenMessageProps = {

}

const DEFAULT_MESSAGE: TScreenMessage = {
    message: "",
    status: 0,
    returnPath: ""
}
const ScreenMessage: FC<TScreenMessageProps> = () => {
    const { screenMessage, setScreenMessage } = useCommons();

    useEffect(() => {
    }, [screenMessage])
    const close = () => {
        setScreenMessage(DEFAULT_MESSAGE);
        if (screenMessage.returnPath !== undefined && screenMessage.returnPath !== "") {
            window.location.href = screenMessage.returnPath;
        }
    }

    const displayMessage = (): string => {

        if (screenMessage.status === 600) {
            return "¡Cuidado!";
        }
        if (screenMessage.status >= 200 && screenMessage.status <= 226) {
            return "¡Todo ha salido perfecto!";
        }
        if (screenMessage.status >= 400 && screenMessage.status <= 451) return "¡Un problema ha ocurrido!";
        return "¡Se ha producido con error!";
    }
    const displayIcon = (): string => {
        if (screenMessage.status >= 200 && screenMessage.status <= 226)
            return "tick.png";
        if ((screenMessage.status >= 400 && screenMessage.status <= 451) || screenMessage.status === 600) return "warning.png";
        return "deny.png";
    }
    const displayPopUp = () => {
        return (<div className="screen-message f-c">
            <img src={UtilService.resolveIconsUrl(displayIcon())} alt="Icono de respuesta" width={30} />
            <h3>{displayMessage()}</h3>
            <p>{screenMessage.message}</p>
            <Button onClick={close} className="type-1-transparent"><p>Cerrar</p></Button>
        </div>)
    }
    return (
        screenMessage.status > 0 ?
            <Overlay classname="screen-message-overlay">
                {screenMessage.status > 0 && displayPopUp()}
            </Overlay> : <></>
    );
}

export default ScreenMessage;