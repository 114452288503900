import { useContext } from 'react';
import { CommonContext } from './CommonContext';




export const useCommons = () => {

    const { commonState, setLoadingScreen, setScreenMessage, setActionConfirmationPopup, toggleContact, toggleLogin, toggleRegistration } = useContext(CommonContext);
    const { loadingScreen, screenMessage, actionConfirmationPopUp, showContact, showLogin, showRegistration } = commonState;

    return {
        screenMessage,
        actionConfirmationPopUp,
        setScreenMessage,
        loadingScreen,
        setLoadingScreen,
        setActionConfirmationPopup,
        toggleContact,
        toggleLogin,
        toggleRegistration,
        showContact,
        showLogin,
        showRegistration
    }
}


