import TNews from 'models/types/TNews';
import { TNewsState } from 'models/states/TNewsState';



type newsAction =
    | { type: 'addNews', payload: TNews }
    | { type: 'deleteNews', payload: number }
    | { type: 'setNews', payload: TNews[] }
    | { type: 'updateNews', payload: TNews }


export const newsReducer = (state: TNewsState, action: newsAction): TNewsState => {

    switch (action.type) {
        case 'addNews':
            return {
                ...state,
                news: [...state.news, action.payload]
            }
        case 'deleteNews':

            return {
                ...state,
                news: state.news.filter((n, i) => n.idNews !== action.payload)
            }
        case 'setNews':
            return {
                ...state,
                news: action.payload
            }
        case 'updateNews':
            let news: TNews[] = state.news;
            for (let i = 0; i < news.length; i++) {
                if (news[i].idNews === action.payload.idNews)
                    news[i] = action.payload;
            }
            return {
                ...state,
                news
            }
        default:
            return state;
    }

}