import { useUsers } from 'contexts/UsersContext/useUsers';
import { TNotification } from 'models/types/TNotification';
import UtilService from 'services/utilService';
import './Notification.css';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
interface IProps {
    notification: TNotification,
    color: string
}
export default function Notification({ notification, color }: IProps) {
    const { userLogged, deleteNotification } = useUsers();
    useEffect(() => { }, [userLogged])
    const resolveUrl = (): string => {
        let url = "";
        switch (notification.type) {
            case "WELCOME":
                url = "/perfil"
                break;
            case "INSCRIPTED USER":
                url = `/management/users/${notification.id}`
                break;
            case "DIRECTOR":
            case "PROFESSOR":
            case "DIRECTOR REGISTER NEW MEMBERS":
                url = `/management/users`
                break;
            case "EVENT":
                if (userLogged && userLogged.role !== "ADMIN")
                    url = `/events/${notification.id}`
                else url = `/management/events`
                // url = `/management/events/${notification.id}`
                break;
            case "ACADEMY":
                url = `/management/academies`
                break;
            case "INSCRIPTED ACADEMY":
                url = `/management/academies/${notification.id}`
                break;
            case "NEWS":
                url = `/noticias/${notification.id}`
                break;
        }

        return url;
    }

    return (
        <section className='notification' style={{
            background: color
        }}>
            <NavLink to={resolveUrl()} onClick={() => deleteNotification(notification.idNotification, userLogged?.idUser ?? 0)}>
                <h4>{notification.title}</h4>
                <p>{notification.message}</p>
                <p>{notification.createdAt && UtilService.formatDateTimeNotification(notification.createdAt)}</p>
            </NavLink>
            <div onClick={() => deleteNotification(notification.idNotification, userLogged?.idUser ?? 0)}>x</div>
        </section>
    )
}
