import { FC, useEffect, useState } from 'react';

import UserService from 'services/userService';
import './ManagementView.scss';

export type TManagementViewProps = {
    renderScreenMessage?: () => JSX.Element,
    renderMain?: (screenProps: any) => JSX.Element,
    renderSide?: (screenProps: any) => JSX.Element,
}
export const ManagementView: FC<TManagementViewProps> = ({ renderMain, renderSide, renderScreenMessage }) => {
    useEffect(() => {
        UserService.verify();
    },[])

    
    const [pageProps, setPageProps] = useState<any>({
        loading: false
    });
    return (
        <>
            {renderScreenMessage ? renderScreenMessage() : null}
            <section className='management'>
                {renderSide ? renderSide(pageProps) : null}
                {renderMain ? renderMain(pageProps) : null}
            </section>
        </>
    );
};