import { useCommons } from "contexts/CommonContext/useCommons";
import TDiscipline from "models/types/TDiscipline";
// import { TDisciplineServiceResponse } from "models/responses/TDisciplineServiceResponse";
import { TDisciplineState } from "models/states/TDisciplineState";
import { useReducer } from "react";





import DisciplineService from "services/disciplinesService";
import { DisciplinesContext } from "./DisciplinesContext";
import { disciplinesReducer } from "./DisciplinesReducer";



interface IProps {
    children: JSX.Element | JSX.Element[]
}



const INITIAL_STATE: TDisciplineState = {
    disciplines: [],
}




export const DisciplineProvider = ({ children }: IProps) => {
    const [disciplinesState, dispatch] = useReducer(disciplinesReducer, INITIAL_STATE);
    const { setLoadingScreen, setScreenMessage } = useCommons();

    const fetchDisciplines = async (): Promise<void> => {
        const { message, payload, status } = await DisciplineService.getDisciplines();
        if (status === 200) {
            setDisciplinesToContext(payload);
        } else {
            setScreenMessage({ message, status });
        }
    }
    const createDiscipline = async (disciplineToCreate: TDiscipline): Promise<void> => {
        setLoadingScreen(true);
        disciplineToCreate = { ...disciplineToCreate, color: disciplineToCreate.color.replace("#", "") }
        const { message, payload, status } = await DisciplineService.createDiscipline(disciplineToCreate)
        if (status === 200) {
            addDisciplineToContext(payload);
            setScreenMessage({ message, status, returnPath: "/management/disciplines" });
        } else {
            setScreenMessage({ message, status });
        }

        setLoadingScreen(false);
    }


    const updateDiscipline = async (disciplineToUpdate: TDiscipline): Promise<TDiscipline | undefined> => {
        disciplineToUpdate = { ...disciplineToUpdate, color: disciplineToUpdate.color.replace("#", "") }
        setLoadingScreen(true);
        const { message, payload, status } = await DisciplineService.updateDiscipline(disciplineToUpdate)
        if (status === 200) {
            updateDisciplineFromContext(payload);
            setLoadingScreen(false);
        }
        setLoadingScreen(false);
        setScreenMessage({ message, status });
        return payload;
    }


    const deleteDiscipline = async (id: number): Promise<void> => {
        setLoadingScreen(true);
        const {  status, message } = await DisciplineService.deleteDiscipline(id);
        if (status === 200) {
            deleteDisciplineFromContext(id);
        } else {
            setScreenMessage({ message, status });
        }
        setLoadingScreen(false);
    }


    const addDisciplineToContext = (value: TDiscipline): void => {
        dispatch({ type: 'addDiscipline', payload: value })
    }

    const setDisciplinesToContext = (value: TDiscipline[]): void => {
        dispatch({ type: 'setDisciplinesToContext', payload: value })
    }
    const deleteDisciplineFromContext = (idDiscipline: number): void => {
        dispatch({ type: 'deleteDiscipline', payload: idDiscipline })
    }
    const updateDisciplineFromContext = (event: TDiscipline): void => {
        dispatch({ type: 'updateDiscipline', payload: event })
    }

    const getDisciplineByIdFromContext = (idDiscipline: number): TDiscipline | undefined => {
        return disciplinesState.disciplines.find(discipline => discipline.idDiscipline === idDiscipline);
    }



    return (
        <DisciplinesContext.Provider value={{
            disciplinesState,
            fetchDisciplines,
            addDisciplineToContext,
            deleteDisciplineFromContext,
            updateDisciplineFromContext,
            deleteDiscipline,
            createDiscipline,
            updateDiscipline,
            setDisciplinesToContext,
            getDisciplineByIdFromContext
        }}>
            {children}
        </DisciplinesContext.Provider>
    )

}



