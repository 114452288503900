

import { useCommons } from "contexts/CommonContext/useCommons";
import { useUsers } from "contexts/UsersContext/useUsers";
import { useState } from "react";

import { TUserRegistrationForm } from "models/types/TUserRegistrationForm";
import UtilService from "services/utilService";
import { useForms } from "shared/hooks/useForms";
import FIELDS_INPUTS_SETTINGS from "static/FIELDS_INPUTS_SETTINGS";
import Button from "../button/Button";
import { Input } from "../input/Input";
import Overlay from "../overlay/Overlay";
import RadioButton from "../radio_button/RadioButton";
import './RegistrationBox.scss';


export default function RegistrationBox() {
    const { showRegistration, toggleRegistration } = useCommons();
    const { signUp } = useUsers();
    const [showSuccess, setShowSuccess] = useState<boolean>();
    const { validateForm } = useForms();
    const [validationMessage, setValidationMessage] = useState<string>();
    const [userRegistration, setUserRegistration] = useState<TUserRegistrationForm>({
        name: "",
        lastname: "",
        email: "",
        role: "DIRECTOR"
    })
    const REGISTRATION_VALIDATION_FORM_MESSAGE = {
        email: "El correo es necesario, debe estar completo y sin errores",
        name: "El nombre  es necesario, debe estar completo y sin errores",
        lastname: "El apellido  es necesario, debe estar completo y sin errores",
    }


    const handleChange = (value: string, field?: string) => {
        field && setUserRegistration({
            ...userRegistration,
            [field]: value
        })
    }

    const click = async () => {
        if (validateForm<TUserRegistrationForm>(REGISTRATION_VALIDATION_FORM_MESSAGE, userRegistration, setValidationMessage)) {
            let success = await signUp(userRegistration);
            if (success) {
                setShowSuccess(true);
                setUserRegistration({
                    name: "",
                    lastname: "",
                    email: "",
                    role: "DIRECTOR"
                })
            }
        }

    }

    const close = () => {
        setShowSuccess(false);
        toggleRegistration();

    }


    return (
        showRegistration ? <Overlay classname={`registration_box`} id="registration-box">
            <section >
                <div></div>
                {((!showSuccess)) ?
                    <div className="registration_wrapper">
                        <h3>Formulario de registro</h3>
                        <p className="text">
                            <strong>Tenga en cuenta que el formulario de registro es para una pre-inscripción</strong><br />
                            Recuerda contactar con HLoloRossi, previa o posteriormente al registro<br />
                            {/* Luego de completar los campos, seleccione el rol con el cual desea registrarse */}
                        </p>
                        <div>
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.lastname} value={userRegistration.lastname} />
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.name} value={userRegistration.name} />
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.email} value={userRegistration.email} />
                        </div>
                        <div>
                            <RadioButton selected={userRegistration.role === "DIRECTOR"} setValue={(value: string) => handleChange(value, "role")} value={"DIRECTOR"} label={"Director"} />
                            <RadioButton selected={userRegistration.role === "PROFESSOR" ? true : false} setValue={(value: string) => handleChange(value, "role")} value={"PROFESSOR"} label={"Profesor"} />
                            <RadioButton selected={userRegistration.role === "STUDENT" ? true : false} setValue={(value: string) => handleChange(value, "role")} value={"STUDENT"} label={"Estudiante"} />
                        </div>
                        {validationMessage && <p className="validation-message">{validationMessage}</p>}
                        <div className="flex-row buttons-wrapper">
                            <button className="btn-cmn flex-row" onClick={() => toggleRegistration()}>Cerrar</button>
                            <button className="btn-cmn flex-row" onClick={() => click()}>Enviar formulario</button>
                        </div>
                    </div>
                    : <div className="signup-success-message">
                        <img alt="Check img" src={UtilService.resolveIconsUrl("tick.png")} width={60} />
                        <h3 className="titles">!Se ha registrado!</h3>
                        <p>Pronto nos contactaremos con vos y validaremos la petición. Si la validación es correcta le enviaremos el correo con el enlace de activación de cuenta</p>
                        <h2 className="subtitles">¡Muchísimas gracias!</h2>
                        <Button onClick={close} className="type-2-transparent" >Cerrar</Button>
                    </div>

                }
            </section>

        </Overlay> : <></>
    )
}
