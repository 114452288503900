import { useAcademies } from "contexts/AcademiesContext/useAcademies";
import { useDisciplines } from "contexts/DisciplinesContext/useDisciplines";
import { useEvents } from "contexts/EventContext/useEvents";
import { useNews } from "contexts/NewsContext/useNews";
import { useUsers } from "contexts/UsersContext/useUsers";
import UserService from "services/userService";

export const useFetchData = () => {
    const { fetchUsers } = useUsers();

    const { fetchAcademies } = useAcademies();
    const { fetchDisciplines } = useDisciplines();
    const { fetchEvents } = useEvents();
    const { fetchNews } = useNews();

    const fetchData = async () => {
        UserService.verify();
        fetchDisciplines();
        fetchUsers();
        fetchNews();
        fetchAcademies();
        fetchEvents();
    
    }
    return {fetchData}
}