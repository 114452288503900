import { FC, useState } from 'react';



export type TScreenProps = {
    renderHeader?: (screenProps: any) => JSX.Element,
    renderBody?: (screenProps: any) => JSX.Element,
    renderFooter?: (screenProps: any) => JSX.Element,
}
export const Screen: FC<TScreenProps> = ({ renderHeader, renderBody, renderFooter }) => {
    const [pageProps, setPageProps] = useState<any>({
        loading: false
    });
    return (
        <>
            {renderHeader ? renderHeader(pageProps) : null}
            {renderBody ? renderBody(pageProps) : null}
            {renderFooter ? renderFooter(pageProps) : null}
        </>
    );
};