import { FC, useEffect, useState } from "react"
import './Input.scss'
import useInput from "../../hooks/useInput"
import { TFieldInputSetting } from "../../../models/types/TFieldInputSettings"
import UtilService from "../../../services/utilService"

type TInput = {
  onChange?: (value: any, field: string) => void,
  value?: string | number
  type?: "text" | "number" | "email" | "phone" | "password" | "datetime-local",
  disabled?: boolean,
  fieldInputSettings: TFieldInputSetting
  className?: string,
  showMaxLenght?: boolean,
}
export const Input: FC<TInput> = ({ onChange = () => { }, value = "", disabled = false, fieldInputSettings: { maxLength = 0, message = "", name, placeholder, type, validation }, className = "", showMaxLenght = false }) => {
  const { characters, onChangeInput, isValid } = useInput({ maxLength: maxLength, onChange, validation, length: (value as string).length });
  const [visible, setVisible] = useState<boolean>(true);
  useEffect(() => { }, [visible])

  return (
    <div className={`input-container ${className}`}>
      <section>
        { type === "color" && <p>{placeholder}</p>}
        <input
          type={visible ? type : "text"}
          value={value}
          disabled={disabled}
          onChange={e => {
            onChangeInput(e.target.value, name)
          }}
          placeholder={placeholder}
        />
        {type === "password" && (
          visible ?
            <button type="button" onClick={() => setVisible(false)} className="hide-btn">
              <img src={UtilService.resolveIconsUrl("remove_red_eye_black_24dp.svg")} alt="Boton de visibilidad" />
            </button>
            :
            <button type="button" onClick={() => setVisible(true)} className="hide-btn">
              <img src={UtilService.resolveIconsUrl("visibility_off_black_24dp.svg")} alt="Boton de visibilidad" />
            </button>
        )}
        {(maxLength > 0 && showMaxLenght &&  type !== "color") ? <div className="input-maxlenght">{characters}/{maxLength}</div> : <div></div>}
        {(validation) ? <WarningInputMessage message={message} /> : <div></div>}
      </section>
      {!isValid && <p>{message}</p>}

    </div>
  )
}

export const WarningInputMessage: FC<{ message?: string }> = ({ message = "" }) => {
  return (
    <div className="warning-input-message">
      <img alt="Signo de interrogación" width={15} src={UtilService.resolveIconsUrl("help_black_24dp.svg")} />
      <p>{message}</p>
    </div>
  )
}
