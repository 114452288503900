
import { httpServer } from "../clients/server";

import TNotificationRequest from "models/request/INotificationRequest";
import { TResponse } from "models/responses/TResponse";
import { TNotification } from "models/types/TNotification";
import TUser from "models/types/TUser";
export default class NotificationService {
    private static notificationsController = "/notifications";
    static getAll() {
        return httpServer.get(`${this.notificationsController}`)
            .then(({ data }: any): TResponse<TNotification[]> => data as TResponse<TNotification[]>)
            .catch(({ response }: any): TResponse<TNotification[]> => response.data as TResponse<TNotification[]>);
    }

    static create(notificationRequest: TNotificationRequest): Promise<TResponse<TNotification>> {
        return httpServer.post(`${this.notificationsController}/create`, notificationRequest)
            .then(({ data }: any): TResponse<TNotification> => data as TResponse<TNotification>)
            .catch(({ response }: any): TResponse<TNotification> => response.data as TResponse<TNotification>);
    }
    static getNotificationsByUserId(userId: number): Promise<TResponse<TNotification[]>> {
        return httpServer.get(`${this.notificationsController}/notifications_by_user_id/?id_user=${userId}`)
            .then(({ data }: any): TResponse<TNotification[]> => data as TResponse<TNotification[]>)
            .catch(({ response }: any): TResponse<TNotification[]> => response.data as TResponse<TNotification[]>);
    }

    static delete(idNotification: number, idUser: number): Promise<TResponse<TUser>> {
        return httpServer.delete(`${this.notificationsController}/delete/${idNotification}/user/${idUser}`)
            .then(({ data }: any): TResponse<TUser> => data as TResponse<TUser>)
            .catch(({ response }: any): TResponse<TUser> => response.data as TResponse<TUser>);
    }

}


