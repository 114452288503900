import TAcademy from "models/types/TAcademy";
import { httpServer, } from "../clients/server";

import { TResponse } from "models/responses/TResponse";
import TDeleteAcademyRequest from "models/types/requests/TDeleteAcademyRequest";
import { TInscribeAcademyRequest } from "models/types/requests/TInscribeAcademyRequest";
import TProfessor from "models/types/TProfessor";
import TStudent from "models/types/TStudent";
export default class AcademyService {

    private static academiesController = "/academies";

    static getAllAcademies() {

        return httpServer.get(`${this.academiesController}/`)
            .then(({ data }: any) => data)
            .catch((err: any) => err);
    }
    static createAcademy(discipline: TAcademy): Promise<TResponse<TAcademy>> {
        return httpServer.post(`${this.academiesController}/create`, discipline)
            .then(({ data }: any): TResponse<TAcademy> => {
                return data as TResponse<TAcademy>;
            })
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    }

    static inscribe(inscribeAcademyRequest: TInscribeAcademyRequest): Promise<TResponse<TAcademy>> {
        return httpServer.post(`${this.academiesController}/inscribe`, inscribeAcademyRequest)
            .then(({ data }: any): TResponse<TAcademy> => data as TResponse<TAcademy>)
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    }

    static updateAcademy(disciplineToUpdate: TAcademy): Promise<TResponse<TAcademy>> {
        return httpServer.put(`${this.academiesController}/update`, disciplineToUpdate)
            .then(({ data }: any): TResponse<TAcademy> => {
                return data as TResponse<TAcademy>;
            })
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    }
    static addMembersByDirector(idDirector: number, idAcademy: number, professorsToAdd: TProfessor[], studentsToAdd: TStudent[]): Promise<TResponse<TAcademy>> {
        return httpServer.post(`${this.academiesController}/update/${idAcademy}/add-members/${idDirector}`, {professorsToAdd,studentsToAdd})
            .then(({ data }: any): TResponse<TAcademy> => {
                return data as TResponse<TAcademy>;
            })
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    } 
    static deleteAcademy(deleteAcademyRequest: TDeleteAcademyRequest): Promise<TResponse<string>> {
        return httpServer.put(`${this.academiesController}/delete`, deleteAcademyRequest)
            .then(({ data }: any): TResponse<string> => data as TResponse<string>)
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);

    }


    static updateAcademyAvailability(id: number, changeEnableTo: string) {
        return httpServer.patch(`${this.academiesController}/update/${id}/enable/${changeEnableTo}`)
            .then(({ data }: any): TResponse<TAcademy> => data as TResponse<TAcademy>)
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    }

    static deleteImage(id: number, image: string) {
        return httpServer.patch(`${this.academiesController}/delete/${id}/image/${image}/`)
            .then(({ data }: any): TResponse<TAcademy> => data as TResponse<TAcademy>)
            .catch(({ response }: any): TResponse<TAcademy> => response.data as TResponse<TAcademy>);
    }



    static uploadImages(academy: TAcademy, logo?: File, layoutImage?: File, images?: File[]): FormData {
        let formData = new FormData();
        logo && formData.append("images", logo, academy.logo);
        layoutImage && formData.append("images", layoutImage, academy.layoutImage);
        if (images && images.length > 0) {
            images.forEach((image: File, index: number) => {
                formData.append("images", image, academy.images[index]);

            })
        }
        return formData;
    }


}





