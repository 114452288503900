import TFormation from "models/types/TFormation";
import TProfessor from "models/types/TProfessor";
import { TRoles } from "models/types/TRoles";
import TUser from "models/types/TUser";
import FIELDS_INPUTS_SETTINGS from "static/FIELDS_INPUTS_SETTINGS";

export const useForms = () => {

    const validateForm = <TRequest extends Object>(messages: any, object: TRequest, setValidationMessage: (message: string) => void, keysToOmit?: (keyof TRequest)[]): boolean => {
        type TKeys = keyof TRequest
        let isValid: boolean = true;
        Object.keys(object).forEach((k, i) => {
            if ((!keysToOmit || !keysToOmit.includes(k as TKeys)) && (object[k as TKeys] === undefined || object[k as TKeys]?.toString() === "" || object[k as TKeys]?.toString() === "0")) {            
                setValidationMessage(messages[k as TKeys]);
                isValid = false;
            }
        })
        isValid && setValidationMessage("");
        return isValid;
    }

    const validateCurriculum = (user: TUser, setValidationMessage: (message: string) => void): boolean => {
        if (user && (user.role === "PROFESSOR" || user.role === "DIRECTOR")) {
            if (!FIELDS_INPUTS_SETTINGS.summary.validation.test((user as TProfessor).curriculum.summary)) {
                setValidationMessage("El resumen/presentación del profesor debe estar completo y sin errores");
                return false;
            }
            if ((user as TProfessor).curriculum.formations && (user as TProfessor).curriculum.formations.length > 0) {
                let formations: TFormation[] = (user as TProfessor).curriculum.formations;
                for (let i = 0; i < formations.length; i++) {
                    if (!FIELDS_INPUTS_SETTINGS.titleFormation.validation.test(formations[i].title)) {
                        setValidationMessage("Los títulos de las formaciones deben estar completos y sin errores");
                        return false;
                    }
                    if (!FIELDS_INPUTS_SETTINGS.descriptionFormation.validation.test(formations[i].description)) {
                        setValidationMessage("Las descripciones de las formaciones deben estar completas y sin errores");
                        return false;
                    }
                }
            }
        }
        return true;
    }

    const checkPassword = (password: string): boolean => {
        let regExp: RegExp = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,12}$/;
        return regExp.test(password);
    }

    const resolveRole = (role: string): keyof TRoles => {
        switch (role) {
            case "Estudiante": return "STUDENT";
            case "Profesor": return "PROFESSOR";
            case "Director": return "DIRECTOR";
            case "Administrador": return "ADMIN";
            default: return "USER";
        }

    }

    const resolveRoleForDropDown = (role: string): string => {
        switch (role) {
            case "PROFESSOR": return "Profesor";
            case "DIRECTOR": return "Director";
            case "ADMIN": return "Administrador";
            default: return "Estudiante";
        }

    }
    return {
        validateForm,
        checkPassword,
        resolveRole,
        resolveRoleForDropDown,
        validateCurriculum
    }
}