import { FC } from "react";
import './Button.scss'
import { NavLink } from "react-router-dom";
type TButtonProps = {
    onClick?: () => void,
    children?: string | JSX.Element | JSX.Element[],
    className?:string,
    to?:string,
    type?:"button" | "submit" | "reset"
}

const Button: FC<TButtonProps> = ({onClick = () => {},className,children, to,type = "button" }) => {
    return (
        !to ?
        <button type={type} className={"button " + className} onClick={() => onClick()}>
            {children}
        </button> : 
        <NavLink type="button" to={to} className={"button " + className} onClick={() => onClick()}>
          {children}
        </NavLink>
    );
}

export default Button;