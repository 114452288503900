
import { useState } from "react";

import './ContactBox.scss';

import { useCommons } from "contexts/CommonContext/useCommons";
import IEmailRequest from "models/request/IEmailRequest";
import { Input } from "../input/Input";
import FIELDS_INPUTS_SETTINGS from "static/FIELDS_INPUTS_SETTINGS";
import { TextAreaInput } from "../textarea_input/TextAreaInput";
import { useForms } from "shared/hooks/useForms";
import { useUsers } from "contexts/UsersContext/useUsers";

export default function ContactBox() {
    const { toggleContact, showContact } = useCommons();
    const { validateForm } = useForms();
    const { sendContactEmail } = useUsers();

    const [success, displaySuccess] = useState<boolean>(false);
    const [validationMessage, setValidationMessage] = useState<string>("");
    const [contactForm, setContactForm] = useState<IEmailRequest>({
        name: "",
        lastname: "",
        recipient: "",
        msgBody: "",
        subject: "Contacto"
    })
    const CONTACT_VALIDATION_FORM_MESSAGE = {
        recipient: "El correo es necesario, debe estar completo y sin errores",
        name: "El nombre  es necesario, debe estar completo y sin errores",
        lastname: "El apellido  es necesario, debe estar completo y sin errores",
        msgBody: "El mensaje es necesario, debe estar completa y sin errores",
    }


    const handleChange = (value: string, field: string) => {
        setContactForm({
            ...contactForm,
            [field]: value
        })

    }
    const handleSubmit = async () => {
        if (validateForm<IEmailRequest>(CONTACT_VALIDATION_FORM_MESSAGE, contactForm, setValidationMessage)) {
            const response = await sendContactEmail(contactForm);
            if (response) displaySuccess(true);
            setContactForm({
                name: "",
                lastname: "",
                recipient: "",
                msgBody: "",
                subject: "Contacto"
            })
        }
    }
    const close = () => {
        displaySuccess(false);
        toggleContact();
        setContactForm({
            name: "",
            lastname: "",
            recipient: "",
            msgBody: "",
            subject: "Contacto"
        });
    }

    return (
        showContact ? <div className={`contact_container`}>

            {/* Background */}
            <section>
                <div></div>
                {!success ?
                    <div className="contact_box box-sw">
                        <h3>Formulario de contacto</h3>
                        <div>
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.lastname} value={contactForm.lastname} />
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.name} value={contactForm.name} />
                            <Input className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.recipient} value={contactForm.recipient} />
                            <TextAreaInput className="type-1" showMaxLenght={true} onChange={handleChange} fieldInputSettings={FIELDS_INPUTS_SETTINGS.msgBody} value={contactForm.msgBody} />
                        </div>
                        {validationMessage !== "" && <p className="validation-message">{validationMessage}</p>}
                        <div className="flex-row buttons-wrapper">
                            <button onClick={() => toggleContact()} className=" flex-row">Cerrar</button>
                            <button onClick={() => handleSubmit()} className=" flex-row">Enviar</button>
                        </div>

                    </div>
                    :
                    <section className={`contact_success_message_box`}>
                        <div>
                            <h3>¡Correo enviado!</h3>
                            <p>Responderemos su consulta via correo <br />¡Muchas gracias por contactarnos!</p>
                        </div>
                        <div>
                            <button onClick={() => close()} className=" flex-row">Cerrar</button>
                        </div>
                    </section>
                }
            </section>

        </div> : <></>
    )
}

