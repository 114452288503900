import { FC, useEffect } from "react";
import './ActionConfirmationPopUp.scss'
import Button from "../button/Button";
import UtilService from "services/utilService";
import { useCommons } from "contexts/CommonContext/useCommons";

const ActionConfirmationPopUp: FC<any> = () => {
    const { actionConfirmationPopUp, setActionConfirmationPopup } = useCommons();
    useEffect(() => {
    }, [actionConfirmationPopUp])
    const confirm = () => {
        if (actionConfirmationPopUp.action) {
            actionConfirmationPopUp.action();
            // window.location.reload();
        }

        setActionConfirmationPopup();
    };
    return (
        actionConfirmationPopUp.action && actionConfirmationPopUp.message ? <section className={`action-confirmation-pop-up`} >
            <form>
                <img src={UtilService.resolveIconsUrl("warning.svg")} alt="" />
                <h3>{actionConfirmationPopUp.message}</h3>
                <p>¿Estas seguro de confirmar esta acción?</p>
                <div>
                    <Button onClick={() => setActionConfirmationPopup()} className="type-3-transparent">Cancelar</Button>
                    <Button type="submit" onClick={confirm} className="type-1-transparent">Aceptar</Button>
                </div>
            </form>
        </section> : <></>
    );
}

export default ActionConfirmationPopUp;