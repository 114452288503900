import { TResponse } from "models/responses/TResponse";
import { httpServer, httpServerMultipart } from "../clients/server";
export default class FileService {
    private static storageController = "/storage"

    static getImage(image: any) {
        return httpServer.get(`${this.storageController}/${image}`)
            .then(({ data }: any): TResponse<string> => {
                return data as TResponse<string>;
            })
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }

    static deleteImage(name: string) {
        return httpServer.delete(`${this.storageController}/${name}`)
            .then(({ data }: any): TResponse<string> => {
                return data as TResponse<string>;
            })
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }

    static deleteImages(images: string[]) {
        return httpServer.post(`${this.storageController}/delete/images`, images)
            .then(({ data }: any): TResponse<string> => {
                return data as TResponse<string>;
            })
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }



    static uploadImg(image: FormData) {
        return httpServerMultipart.post(`${this.storageController}/upload_image`, image)
            .then(({ data }: any): TResponse<string> => {
                return data as TResponse<string>;
            })
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }

    static uploadImages(images: FormData) {
        return httpServerMultipart.post(`${this.storageController}/upload_images`, images)
            .then(({ data }: any): TResponse<string> => {
                return data as TResponse<string>;
            })
            .catch(({ response }: any): TResponse<string> => response.data as TResponse<string>);
    }


}


