import { useContext } from 'react';
import { EventsContext } from './EventsContext';





export const useEvents = () => {

    const {
        createEvent,
        deleteEvent,
        deleteImage,
        eventsState,
        fetchEvents,
        getEventFromByIdFromContext,
        setEventsToContext,
        updateEventFromContext,
        updateAvailability
    } = useContext(EventsContext);
    const { events } = eventsState;

    return {
        createEvent,
        deleteEvent,
        deleteImage,
        events,
        eventsState,
        fetchEvents,
        getEventFromByIdFromContext,
        setEventsToContext,
        updateEventFromContext,
        updateAvailability
    }
}


