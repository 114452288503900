
import TProfessor from 'models/types/TProfessor';
import TStudent from 'models/types/TStudent';
import { default as TUser } from 'models/types/TUser';


import { IChangePasswordRequest } from 'models/request/IChangePasswordRequest';
import TUserRequest from 'models/types/TUserRequest';
import { TUserSignIn } from 'models/types/TUserSignIn';


import IEmailRequest from 'models/request/IEmailRequest';
import IForgotPasswordRequest from 'models/request/IForgotPasswordRequest';
import TUsersState from 'models/states/TUsersState';
import TProfessorRequest from 'models/types/requests/TProfessorRequest';
import TStudentRequest from 'models/types/requests/TStudentRequest';
import TUserLogged from 'models/types/TUserLogged';
import { TUserRegistrationForm } from 'models/types/TUserRegistrationForm';
import { createContext } from 'react';


export type UsersContextType = {
  addUser: (user: TUser) => boolean,
  sendActivationLink: (email: string) => Promise<void>,
  deleteNotification: (idNotification: number, idUser: number) => Promise<void>,
  validateActivationLink: (hash: string) => Promise<TUser | undefined>,
  changePassword: (changePasswordRequest: IChangePasswordRequest) => Promise<boolean>,
  inscribeStudent: (student: TStudent, hash: string, profileImg: File) => Promise<boolean>,
  inscribeProfessor: (professor: TProfessor, hash: string, profileImg: File, curriculumImg?: File) => Promise<TProfessor | undefined>,
  createProfessor: (userRequest: TProfessorRequest, profileImg: File, curriculumImg?: File) => Promise<void>
  createStudent: (userRequest: TStudentRequest, profileImg: File, curriculumImg?: File) => Promise<void>,
  createUser: (userRequest: TUserRequest, profileImg: File, curriculumImg?: File) => Promise<void>,
  deleteUser: (id: number) => void,
  deleteUserByIdFromContext: (id: number) => void,
  deleteUserFromContext: (user: TUser) => void
  fetchUsers: () => Promise<void>
  getProfessors: () => TProfessor[],
  getDirectors: () => TProfessor[],
  getStudents: () => TStudent[]
  getUserByIdFromContext: (idUser: number) => TUser | undefined,
  sendForgotPasswordRequest: (forgotPasswordRequest: IForgotPasswordRequest) => Promise<boolean>,
  setUserLogged: (user: TUserLogged) => void,
  setUsersToContext: (user: TUser[]) => void,
  signIn: (user: TUserSignIn) => Promise<boolean>,
  signOut: () => void,
  signUp: (user: TUserRegistrationForm) => Promise<boolean>,
  updateAvailability: (id: number, availability: boolean) => Promise<TUser | undefined>,
  updateUserFromContext: (user: TUser) => void
  updateUser: (user: TUser, profileImg?: File, curriculumImg?: File) => Promise<TUser | undefined>
  sendContactEmail: (emailRequest: IEmailRequest) => Promise<boolean>
  usersState: TUsersState,

}


export const UsersContext = createContext<UsersContextType>({} as UsersContextType);