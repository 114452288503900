import { lazy } from "react";
import { Route } from "./PublicRoutes";




const ManagemenDisciplinesViewLazy = lazy(() => import('../views/private/management/management_disciplines_view/ManagementDisciplinesView'));
const ManagementDisciplineDetailViewLazy = lazy(() => import('../views/private/management/management_disciplines_view/management_discipline_detail_view/ManagementDisciplineDetailView'));
const ManagementDisciplineCreateViewLazy = lazy(() => import('../views/private/management/management_disciplines_view/management_discipline_create_view/ManagementDisciplineCreateView'));

const ManagementDisciplineRoutes: Route[] = [
    {
        path: 'disciplines/',
        component: ManagemenDisciplinesViewLazy,
        name: 'component: ManagementDisciplineDetailView',
    },
    {
        path: 'disciplines/:id',
        component: ManagementDisciplineDetailViewLazy,
        name: 'component: ManagementDisciplineDetailView',
    },
    {
        path: 'disciplines/create',
        component: ManagementDisciplineCreateViewLazy,
        name: 'component: ManagementDisciplineCreateView',
    }
]

export default ManagementDisciplineRoutes;