import IAddress from 'models/types/TAddress';
import { TSocialMedia } from 'models/types/TSocialMedia';
import ENV_CONFIG from '../static/ENV_CONFIG.json';


const  MEDIAS = [
    "Facebook",
    "TikTok",
    "Instagram",
    "VSCO",
    "Página web",
    "Behance",
    "LinkedIn"
]
export default class UtilService {
    static extensionPermitted: string[] = ["jpg", "jpeg", "png"];
    static readonly MONTHS: string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre", "Noviembre", "Deciembre"];
    static imgMaxSize: number = 5100000;
    static defaultImage: string = "../../../assets/default_image.jpg";
    static appUrl: string | undefined = !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "STATIC" ? ENV_CONFIG.STATIC_URL : (process.env.REACT_APP_ENVIRONMENT === "DEV") ? ENV_CONFIG.DEV_URL : ENV_CONFIG.PROD_URL;
    static days = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

    static resolveIconsUrl(imgName: string) {
        if (imgName === "") return this.defaultImage;
        return "../../../icons/" + imgName;
    }

    static resolveDisciplineImgUrl(imgName?: string) {
        if (!imgName) return this.defaultImage;
        return "../../../disciplines/" + imgName;
    }
    static resolveNewsImgUrl(imgName?: string) {
        // if (!imgName) return this.defaultImage;
        return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "STATIC" ? "../../../news/" + imgName : this.resolveUrlImg(imgName);
    }


    static resolveUserImgUrl(imgName?: string) {
        // if (!imgName) return this.defaultImage;
        return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "STATIC" ? "../../../users/" + imgName : this.resolveUrlImg(imgName);
    }

    static resolveAcademyImgUrl(imgName?: string) {
        // if (!imgName) return this.defaultImage;
        // return "../../../academies/" + imgName;
        return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "STATIC" ? "../../../academies/" + imgName : this.resolveUrlImg(imgName);
    }
    static resolveEventImgUrl(imgName?: string) {
        return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "STATIC" ? "../../../events/" + imgName : this.resolveUrlImg(imgName);
    }

    static resolveAssetImgUrl(imgName?: string) {
        if (imgName === undefined) return this.defaultImage;
        return "../../../assets/" + imgName;
    }


    static formatDateTimeNotification = (dateTime: string) => {
        let [date, time] = dateTime.split(" ");
        let [year, month, day] = date.split("-");
        let [hours, mins] = time.split(":");
        let am_pm = Number(hours) >= 12 ? "pm" : "am"
        hours = hours.length === 2 ? hours : "0" + hours
        mins = mins.length === 2 ? mins : "0" + mins
        return `${day}/${month}/${year} ${hours}:${mins}${am_pm}`

    }

    static sortStringsDesc(value: string, secondValue: string): number {
        if (value > secondValue) return 1;
        if (value < secondValue) return -1;
        return 0;
    }

    static sortStringsAsc(value: string, secondValue: string): number {
        if (value < secondValue) return 1;
        if (value > secondValue) return -1;
        return 0;
    }


    // static resolveImg(urlImg: string | undefined): any {

    //     return urlImg ? {
    //         backgroundImage: `url('${this.appUrl}/storage/${urlImg}')`,
    //         backgroundPosition: 'center',
    //         backgroundSize: 'cover',
    //         backgroundRepeat: 'no-repeat',
    //     } : {}
    // }

    static resolveImg(urlImg: string | undefined): any {

        return urlImg ? {
            backgroundImage: `url('${urlImg}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        } : {}
    }



    static resolveImgContained(urlImg: string | undefined): any {
        return urlImg ? {
            backgroundImage: `url('${urlImg}')`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
        } : {}
    }

    static resolveUrlImg(urlImg: string | undefined): any {
        return urlImg !== "" ? `${this.appUrl}/storage/${urlImg}` : this.defaultImage;
    }



    static resolveBackgroundImageUrl(imageName: string | undefined): any {

        return `url("${imageName ?? ""}")`
    }

    static resolveLocation(address: IAddress): string {
        let location = "";
        if (address.street) location = location + address.street.replace(" ", "+");
        if (address.number) location = location + "+" + address.number.replace(" ", "+");
        if (address.location) location = location + "+" + address.location.replace(" ", "+");
        if (address.province) location = location + "+" + address.province.replace(" ", "+");
        return location;
    }


    static formatProfileImgName(lastname: string, name: string): string {
        return "_user_profile_img_";
    }

    static formatCurriculumImgName(lastname: string, name: string): string {
        return "_user_curriculum_img_";
    }

    static formatAcademyLogoImgName(name: string): string {
        return name.replaceAll(" ", "_").toLowerCase() + "_logo";
    }
    static formatAcademyLayoutImgName(name: string): string {
        return name.replaceAll(" ", "_").toLowerCase() + "_layout-image";
    }

    static formatLinearGradient(colorLeft: string, colorRight: string, direction: string): string {
        return `linear-gradient(to ${direction},${colorLeft}, ${colorRight})`
    }

    static checkString(word?: string): boolean {
        return !word || word === null || word === "" ? false : true;
    }
    static scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    static resolveSocialMediaUrl = (socialMedia: TSocialMedia): string => {
        let url = "";
        switch (socialMedia.type) {
            // "Facebook"
            case MEDIAS[0]:
                if (socialMedia.account.includes("facebook.com") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("facebook.com") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://www.facebook.com/" + socialMedia.account;
                break;
            // "TikTok"
            case MEDIAS[1]:
                if (socialMedia.account.includes("tiktok.com") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("tiktok.com") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://www.tiktok.com/@" + socialMedia.account + "/";
                break;
            // "Instagram"
            case MEDIAS[2]:

                if (socialMedia.account.includes("instagram.com") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("instagram.com") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://instagram.com/" + socialMedia.account + "/";
                break;
            // "VSCO"
            case MEDIAS[3]:
                if (socialMedia.account.includes("vsco.co") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("vsco.co") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://vsco.co/" + socialMedia.account + "/gallery";

                break;
            // "Página web"
            case MEDIAS[4]:
                if (socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else
                    url = socialMedia.account
                break;
            // "Behance"
            case MEDIAS[5]:
                if (socialMedia.account.includes("behance.net") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("behance.net") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://www.behance.net/" + socialMedia.account;
                break;
            // "LinkedIn"
            case MEDIAS[6]:
                if (socialMedia.account.includes("linkedin.com") && socialMedia.account.includes("https"))
                    url = socialMedia.account;
                else if (socialMedia.account.includes("linkedin.com") && !socialMedia.account.includes("https"))
                    url = "https://" + socialMedia.account;
                else url = "https://www.linkedin.com/in/" + socialMedia.account;
                break;

        }
        return url;
    }

    static resolveSocialMediaIcon = (type: string): string => {
        switch (type) {
            case "Facebook":
                return "../icons/facebook-logo.svg"
            case "TikTok":
                return "../assets/tiktok_icon.svg"
            case "Instagram":
                return "../assets/instagram_icon.svg"
            case "VSCO":
                return "../icons/vsco.svg"
            case "Página web":
                return "../icons/web.svg"
            case "Behance":
                return "../icons/behance.svg"
            case "LinkedIn":
                return "../icons/linkedin.svg"
        }
        return ""
    }

    static handleScroll = (ref: any) => {
        window.scrollTo({
            top: ref.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    };

    static renderRoleTag = (role: string) => {
        if (role === "STUDENT") {
            return "#3060e2"
        }
        else if (role === "DIRECTOR") {
            return "#fc3290"
        }
        else if (role === "PROFESSOR") {
            return "#FF8718"
        }
    }

    static formatText = (text: string): string => {
        return text.replace(/\n/g, "<br>");

    }
    static removeImageExtension = (image:string):string => {
        let img = image.split(".")[0];
        return img;
    }
}