import TEventRequest from 'models/request/IEventRequest';
import { TEventState } from 'models/states/TEventState';
import TEvent from 'models/types/TEvent';

import { createContext } from 'react';

export type EventsContextType = {
    fetchEvents: () => Promise<void>,
    createEvent: (eventRequest: TEventRequest, mainImage?: File, images?: File[]) => Promise<void>,
    updateEvent: (eventRequest: TEventRequest, mainImage?: File, images?: File[]) => Promise<void>,
    deleteEvent: (idEvent: number, mainImage?: File, images?: File[]) => void,
    eventsState: TEventState,
    setEventsToContext: (events: TEvent[]) => void,
    updateEventFromContext: (event: TEvent) => void,
    updateAvailability:(id:number,availability:boolean) => Promise<TEvent | undefined>
    deleteImage: (id: number, image: string) => Promise<TEvent | undefined>,
    getEventFromByIdFromContext: (idEvent: number) => TEvent | undefined,

}


export const EventsContext = createContext<EventsContextType>({} as EventsContextType);