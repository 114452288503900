import { lazy } from 'react';
import { Route } from './PublicRoutes';

const ManagemenHomeViewLazy = lazy(() => import('../views/private/management/management_home_view/ManagementHomeView'));

const ManagementHomeRoutes: Route[] = [
    {
        path: '',
        component: ManagemenHomeViewLazy,
        name: 'ManagemenHomeView',
    },
    {
        path: 'home',
        component: ManagemenHomeViewLazy,
        name: 'ManagemenHomeView',
    }]

export default ManagementHomeRoutes;