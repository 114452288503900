import { FC, useState } from "react";
import { TScreenProps } from "../../../../shared/components/screen/Screen";


type TManagementScreenProps = {

} & Omit<TScreenProps, 'renderFooter'>

const ManagementScreen: FC<TManagementScreenProps> = ({ renderHeader, renderBody }) => {
    const [pageProps, setPageProps] = useState<any>({
        loading: false
    });
    return (
        <>
            {renderHeader ? renderHeader(pageProps) : null}
            {renderBody ? renderBody(pageProps) : null}
        </>
    );
}

export default ManagementScreen;