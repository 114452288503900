import { Navigate } from 'react-router-dom';




interface IProps {
    isAuthenticated:boolean,
    children: JSX.Element
}

export default function PrivateRoute({ isAuthenticated, children } : IProps) {
    if (!isAuthenticated) {
        return <Navigate to="/" replace />
    }
    return children
}


