import { TFieldInput } from "models/types/TFieldInput";



const FIELDS_INPUTS_SETTINGS: TFieldInput = {
    socialMediaAccount: {
        maxLength: 100,
        message: "Solo puede contener un máximo de 100 caracteres",
        placeholder: "Cuenta",
        name: "account",
        validation: /^.{0,100}$/,
        type: "text",
    },
    summary: {
        maxLength: 1000,
        message: "Solo puede contener un máximo de 1000 caracteres",
        placeholder: "Resumen",
        name: "summary",
        validation: /^.{0,1000}$/,
        type: "text",
    },
    msgBody: {
        maxLength: 1000,
        message: "Solo puede contener un máximo de 1000 caracteres",
        placeholder: "Texto aqui...",
        name: "msgBody",
        validation: /^.{0,1000}$/,
        type: "text",
    },
    disciplineName: {
        maxLength: 50,
        message: "Solo puede contener un máximo de 50 caracteres",
        placeholder: "Nombre de la disciplina",
        name: "name",
        validation: /^.{0,50}$/,
        type: "text",
    },
    eventTitle: {
        maxLength: 50,
        message: "Solo puede contener un máximo de 50 caracteres",
        placeholder: "Título del evento",
        name: "title",
        validation: /^.{0,50}$/,
        type: "text",
    },
    eventStartDate: {
        maxLength: 50,
        message: "Recuerde debe ingresar una fecha actual o posterior",
        placeholder: "Comienza",
        name: "startDate",
        validation: /^.{0,50}$/,
        type: "datetime-local",
    },
    eventExpirationDate: {
        maxLength: 50,
        message: "Recuerde debe ingresar una fecha u hora posterior a la del comienzo. Si difieren en dias o meses, se creará el evento para cada dia correspondiente",
        placeholder: "Termina",
        name: "expirationDate",
        validation: /^.{0,50}$/,
        type: "datetime-local",
    },
    eventHeader: {
        maxLength: 200,
        message: "Solo puede contener un máximo de 200 caracteres",
        placeholder: "Encabezado/introducción del evento",
        name: "header",
        validation: /^.{0,200}$/,
        type: "text",
    },
    eventSummary: {
        maxLength: 700,
        message: "Solo puede contener un máximo de 700 caracteres",
        placeholder: "Resumen del evento",
        name: "summary",
        validation: /^.{0,700}$/,
        type: "text",
    },
    eventDescription: {
        maxLength: 1000,
        message: "Solo puede contener un máximo de 1000 caracteres",
        placeholder: "Descripción del evento",
        name: "description",
        validation: /^.{0,1000}$/,
        type: "text",
    },
    titleFormation: {
        maxLength: 50,
        message: "Solo puede contener un máximo de 50 caracteres",
        placeholder: "Título",
        name: "title",
        validation: /^.{0,50}$/,
        type: "text",
    },
    descriptionFormation: {
        maxLength: 300,
        message: "Solo puede contener un máximo de 300 caracteres",
        placeholder: "Descripción",
        name: "description",
        validation: /^.{0,300}$/,
        type: "text",
    },
    product: {
        maxLength: 100,
        message: "Solo puede contener un máximo de 100 caracteres",
        placeholder: "Nombre de producto",
        name: "productName",
        validation: /^.{0,100}$/,
        type: "text",
    },
    user: {
        maxLength: 100,
        message: "Solo puede contener un máximo de 100 caracteres",
        placeholder: "Nombre de usuario",
        name: "userName",
        validation: /^.{0,100}$/,
        type: "text",
    },
    newsTitle: {
        maxLength: 50,
        message: "Solo puede contener un máximo de 50 caracteres",
        placeholder: "Título de noticia",
        name: "title",
        validation: /^.{0,50}$/,
        type: "text",
    },
    newsSubtitle: {
        maxLength: 120,
        message: "Solo puede contener un máximo de 120 caracteres",
        placeholder: "Subtítulo de noticia",
        name: "subtitle",
        validation: /^.{0,120}$/,
        type: "text",
    },
    newsHeader: {
        maxLength: 300,
        message: "Solo puede contener un máximo de 300 caracteres",
        placeholder: "Encabezado/Introducción",
        name: "header",
        validation: /^.{0,300}$/,
        type: "text",
    },
    newsBody: {
        maxLength: 1000,
        message: "Solo puede contener un máximo de 1000 caracteres",
        placeholder: "Cuerpo de la noticia",
        name: "body",
        validation: /^.{0,1000}$/,
        type: "text",
    },
    rol: {
        maxLength: 50,
        message: "Si no se selecciona un rol para el usuario, se le asignará uno por defecto",
        placeholder: "Seleccione un rol para el usuario",
        name: "rol",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,50}$/,
        type: "text",
    },
    academyName: {
        maxLength: 60,
        message: "Solo puede contener letras y un máximo de 60 caracteres",
        placeholder: "Nombre de academia",
        name: "name",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,60}$/,
        type: "text",
    },
    academyMainColor: {
        maxLength: 60,
        message: "Evitar elegir color blanco, negro o demasiado oscuros",
        placeholder: "Color principal o distintivo de la academia. El mismo sera utilizado para pequeños detalles en su apartado propio",
        name: "mainColor",
        validation: /^.{0,60}$/,
        type: "color",
    },
    disciplineColor: {
        maxLength: 60,
        message: "Evitar elegir color blanco, negro o demasiado oscuros",
        placeholder: "Color principal o distintivo de la disciplina. El mismo sera utilizado para pequeños detalles en su apartado propio",
        name: "color",
        validation: /^.{0,60}$/,
        type: "color",
    },
    newsColor: {
        maxLength: 60,
        message: "Evitar elegir color blanco, negro o demasiado oscuros",
        placeholder: "Color principal o distintivo de la noticia. El mismo sera utilizado para pequeños detalles en su apartado propio",
        name: "mainColor",
        validation: /^.{0,60}$/,
        type: "color",
    },
    name: {
        maxLength: 50,
        message: "Solo puede contener letras y un máximo de 50 caracteres",
        placeholder: "Nombre",
        name: "name",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,50}$/,
        type: "text",
    },
    lastname: {
        maxLength: 50,
        message: "Solo puede contener letras y un máximo de 50 caracteres",
        name: "lastname",
        placeholder: "Apellido",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,50}$/,
        type: "text",
    },
    document: {
        maxLength: 8,
        message: "Solo puede contener numeros y un máximo de 8 caracteres",
        name: "document",
        placeholder: "Documento",
        validation: /^\d{0,8}$/,
        type: "number",
    },
    password: {
        maxLength: 12,
        message: "Solo puede contener un minimo de 8 caracteres y un máximo de 12 caracteres. Debe contener una letra mayúsculas, un número y un símbolo",
        name: "password",
        placeholder: "Contraseña",
        validation: /^.{0,12}$/,
        type: "password",
    },
    secondPassword: {
        maxLength: 12,
        message: "Solo puede contener un minimo de 8 caracteres y un máximo de 12 caracteres. Debe contener una letra mayúsculas, un número y un símbolo",
        name: "secondPassword",
        placeholder: "Repita la contraseña",
        validation: /^.{0,12}$/,
        type: "password",
    },
    newPassword: {
        maxLength: 12,
        message: "Solo puede contener un minimo de 8 caracteres y un máximo de 12 caracteres. Debe contener una letra mayúsculas, un número y un símbolo",
        name: "newPassword",
        placeholder: "Nueva contraseña",
        validation: /^.{0,12}$/,
        type: "password",
    },
    email: {
        maxLength: 70,
        message: "Solo puede contener un máximo de 70 caracteres",
        name: "email",
        placeholder: "Correo",
        validation: /^[a-zA-Z0-9_.+-@]{0,70}$/,
        type: "email",
    },
    username: {
        maxLength: 70,
        message: "Solo puede contener un máximo de 70 caracteres",
        name: "username",
        placeholder: "Correo",
        validation: /^[a-zA-Z0-9_.+-@]{0,70}$/,
        type: "email",
    },
    recipient: {
        maxLength: 70,
        message: "Solo puede contener un máximo de 70 caracteres",
        name: "recipient",
        placeholder: "Correo",
        validation: /^[a-zA-Z0-9_.+-@]{0,70}$/,
        type: "email",
    },
    phone: {
        maxLength: 14,
        message: "Incluya el número de área. Solo se admiten números y un máximo de 14 caracteres",
        name: "phone",
        placeholder: "Celular",
        validation: /^\d{0,14}$/,
        type: "text",
    },
    //ADDRESS
    country: {
        maxLength: 60,
        message: "Solo puede contener letras y un máximo de 60 caracteres",
        placeholder: "Pais",
        name: "country",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,60}$/,
        type: "text",
    },
    province: {
        maxLength: 60,
        message: "Solo puede contener letras y un máximo de 60 caracteres",
        placeholder: "Provincia/Estado",
        name: "province",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,60}$/,
        type: "text",
    },
    location: {
        maxLength: 60,
        message: "Solo puede contener letras y un máximo de 60 caracteres",
        placeholder: "Localidad",
        name: "location",
        validation: /^[a-zA-ZÀ-ÿ\s]{0,60}$/,
        type: "text",
    },
    number: {
        maxLength: 12,
        message: "Solo puede contener números y un máximo de 12 caracteres",
        placeholder: "Número/Altura",
        name: "number",
        validation: /^\d{0,12}$/,
        type: "text",
    },
    street: {
        maxLength: 60,
        message: "Solo puede contener letras y un máximo de 60 caracteres",
        placeholder: "Calle",
        name: "street",
        validation: /^[a-zA-ZÀ-ÿ\s0-9]{0,60}$/,
        type: "text",
    },
    floor: {
        maxLength: 10,
        message: "Solo puede contener un máximo de 10 caracteres",
        placeholder: "Piso",
        name: "floor",
        validation: /^[a-zA-Z0-9]{0,10}$/,
        type: "text",
    },
    apartment: {
        maxLength: 10,
        message: "Solo puede contener un máximo de 10 caracteres",
        placeholder: "Depto/Puerta",
        name: "apartment",
        validation: /^[a-zA-Z0-9]{0,10}$/,
        type: "text",
    },
}



export default FIELDS_INPUTS_SETTINGS;