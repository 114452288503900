import { lazy } from 'react';
import { Route } from './PublicRoutes';
import ManagementHomeRoutes from './ManagementHomeRoutes';

import ManagementNewsRoutes from './ManagementNewsRoutes';

import ManagementUsersRoutes from './ManagementUsersRoutes';
import ManagementAcademyRoutes from './ManagementAcademyRoutes';

import ManagementDisciplineRoutes from './ManagementDisciplineRoutes';
import ManagementEventRoutes from './ManagementEventRoutes';




const ManagementLazy = lazy(() => {
    return Promise.all([
        import("../views/private/management/ManagementOutlet"),
        new Promise(resolve => setTimeout(resolve, 1000))
    ])
        .then(([moduleExports]) => moduleExports);
});
const childRoutes: Route[] = ManagementHomeRoutes
    .concat(ManagementNewsRoutes)
    .concat(ManagementUsersRoutes)
    .concat(ManagementAcademyRoutes)
    .concat(ManagementDisciplineRoutes)
    .concat(ManagementEventRoutes);

const privateManagementRoutes: Route = {
    path: 'management',
    component: ManagementLazy,
    name: 'Management',
    children: childRoutes
}


export default privateManagementRoutes;