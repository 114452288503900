
export type TNabBtns ={
    path: string, img: string, btnAction: string, alt: string
}
export const MANAGEMENT_LAT_NAV_BTNS: Readonly<TNabBtns[]> = [
    {
        img: "home_white_24dp.svg",
        alt: "Icono de inicio",
        path: "/management/home",
        btnAction: "Inicio"
    },
    {
        img: "school.svg",
        alt: "Icono de academias",
        path: "/management/academies",
        btnAction: "Academias"
    },
    {
        img: "newspaper_white_24dp.svg",
        alt: "Icono de noticias",
        path: "/management/news",
        btnAction: "Noticias"
    },

    {
        img: "groups_white_24dp.svg",
        alt: "Icono de usuarios",
        path: "/management/users",
        btnAction: "Usuarios"
    },
    {
        img: "category_white_24dp.svg",
        alt: "Icono de disciplinas",
        path: "/management/disciplines",
        btnAction: "Disciplinas"
    },
    {
        img: "schedule.svg",
        alt: "Icono de eventos",
        path: "/management/events",
        btnAction: "Eventos"
    },
]