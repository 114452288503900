import { DotLoader } from 'react-spinners';
import './LandingPageLoadingScreen.css';

export default function LandingPageLoadingScreen(): JSX.Element {


    return (
        <div className={`loading_page_loading_screen flex-column`}>
            <div className='flex-column'>
                <h1>
                    Lolo Rossi
                </h1>
                {/* <img src="../assets/HyLoloRossi-footer.svg" alt="logo-loading-screen" /> */}
            </div>
            <div className='flex-column show_in'>
                <DotLoader loading={true} color={"#6046D3"} />
            </div>
        </div>
    )
}
