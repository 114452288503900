import { FC } from "react"
import { TFieldInputSetting } from "../../../models/types/TFieldInputSettings"
import useInput from "../../hooks/useInput"
import { WarningInputMessage } from "../input/Input"
import './TextAreaInput.scss'

type TTextAreaInput = {
    onChange?: (value: any, field: string) => void,
    value?: string | number
    type?: "text" | "number" | "email" | "phone" | "password",
    disabled?: boolean,
    fieldInputSettings: TFieldInputSetting
    className?: string,
    showMaxLenght?: boolean,
}
export const TextAreaInput: FC<TTextAreaInput> = ({ onChange = () => { }, value = "", disabled = false, fieldInputSettings: { maxLength = 0, message = "", name, placeholder, validation }, className = "", showMaxLenght = false }) => {
    const { characters, onChangeInput, isValid } = useInput({ maxLength: maxLength, onChange, validation,length:(value as string).length });
    return (
        <div className={`text-area-container ${className}`}>
            <section>
                <textarea
                    value={value}
                    disabled={disabled}
                    onChange={e => {
                        onChangeInput(e.target.value, name)
                    }}
                    placeholder={placeholder}
                />
                {(maxLength > 0 && showMaxLenght) ? <div className="input-maxlenght">{characters}/{maxLength}</div> : <div></div>}
                {(validation) ? <WarningInputMessage message={message} /> : <div></div>}
            </section>
            {!isValid && <p>{message}</p>}

        </div>
    )
}

