import { useEffect } from 'react'
import './RadioButton.css'

interface IProps {
    value: string,
    setValue: (value: string) => void,
    selected: boolean,
    label:string
}
export default function RadioButton({ value, setValue,selected,label }: IProps): JSX.Element {
    useEffect(() => {},
    [value,selected])
    return (
        <div className='radio_button'>
            <button onClick={() => setValue(value)}>
                <span style={selected ? { backgroundColor: "var(--f-c2-4)" }
                    : 
                    {background:"transparent"}
                }></span>
            </button>
            <p>{label}</p>
        </div>
    )
}
