import { NavLink } from 'react-router-dom';
import './Footer.css';

export default function Footer({ color, mainViewRef, handleScroll, secondViewRef, thirdViewIntegrationRef, thirdViewBenefitsRef }: any): JSX.Element {
    return (
        <div className='footer' >
            <span style={{
                background: color ? `linear-gradient(90deg, black 0% , ${color} 100%)` : `linear-gradient(90deg, var(--f-c2-1) 0% , var(--f-c-1) 100%)`
            }}></span>
            <div style={{
                backgroundImage: "url('../assets/footer-img.svg')",
            }}>
                <section>
                    <div>
                        <section>
                            <div>
                                {/* <Logo size={50}/> */}
                            </div>
                            <div>
                                {/* <a href="#"><img src='../assets/facebook_icon.svg' alt='facebook_icon' /></a> */}
                                <a href="https://www.instagram.com/hyl.avaldeexamenes/"><img src='../assets/instagram_icon.svg' alt='instagram_icon' /></a>
                                {/* <a href="#"><img src='../assets/tiktok_icon.svg' alt='tiktok_icon' /></a> */}
                            </div>
                        </section>
                        <section>
                            <div>
                                <h4>Secciones</h4>
                                <div>
                                    <NavLink to="/">
                                        Inicio
                                    </NavLink>
                                    <NavLink to="/academies">
                                        Academias
                                    </NavLink>
                                    <NavLink to="/events/">
                                        Calendario
                                    </NavLink>
                                </div>

                            </div>
                            <div>
                                <h4>Contactos</h4>
                                <div>
                                    <p>Telefono/Celular: 1155113999</p>
                                    <a href="mailto:avaldeexameneshyl@gmail.com">Correo: avaldeexameneshyl@gmail.com</a>
                                </div>
                            </div>
                            <div>
                                <h4>Comunícate con nosotros</h4>
                                <div>
                                    <p>Recuerda que ante cualquier duda podés comunicarte con nosotros vía What’sApp, correo o Instagram.</p>
                                    <a href="https://wa.link/l1z3ae" rel="noreferrer" className='btn-cmn' target="_blank">Envíanos un mensaje</a>

                                </div>

                            </div>

                        </section>
                    </div>
                    <div>
                        <a href='../assets/CARTA PRESENTACION 2023.pdf' attributes-list="true" download>Terminos y condiciones</a>
                        <span></span>
                    </div>
                </section>

                {color && <div style={{
                    backgroundColor: color,
                }}></div>}

            </div>

        </div >
    )
}
