import { ICommonState } from 'models/states/TCommonState';
import { TScreenMessage } from 'models/types/TScreenMessage';
import { createContext } from 'react';

export type CommonContextType = {
  commonState: ICommonState,
  setLoadingScreen: (value: boolean) => void,
  setScreenMessage: (value:TScreenMessage) => void,
  setActionConfirmationPopup: (action?:() => void, message?:string) => void,
  toggleLogin: () => void
  toggleContact: () => void
  toggleRegistration: () => void

}


export const CommonContext = createContext<CommonContextType>({} as CommonContextType);