import { FC } from "react";
import UtilService from "services/utilService";
import './Logo.scss'
type LogoProps = {
    size?: number,
    style?: any
}

const Logo: FC<LogoProps> = ({ size = 50, style = {} }) => {
    return (<figure className="logo">
        <img style={style} src={UtilService.resolveAssetImgUrl("logo-con-fondo.svg")} alt="Logo HLoloRossi" width={size} height={size} />
    </figure>);
}

export default Logo;