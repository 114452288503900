import { lazy } from "react";
import { Route } from "./PublicRoutes";




const ManagemenNewstViewLazy = lazy(() => import('../views/private/management/management_news_view/ManagementNewsView'));
const ManagementNewsDetailViewLazy = lazy(() => import('../views/private/management/management_news_view/management_news_detail_view/ManagementNewsDetailView'));
const ManagementNewsCreateViewLazy = lazy(() => import('../views/private/management/management_news_view/management_news_create_view/ManagementNewsCreateView'));

const ManagementNewsRoutes: Route[] = [
    {
        path: 'news/',
        component: ManagemenNewstViewLazy,
        name: 'component: ManagementNewsDetailView',
    },
    {
        path: 'news/:id',
        component: ManagementNewsDetailViewLazy,
        name: 'component: ManagementNewsDetailView',
    },
    {
        path: 'news/create',
        component: ManagementNewsCreateViewLazy,
        name: 'component: ManagementNewsCreateView',
    }
]

export default ManagementNewsRoutes;