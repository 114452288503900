import { FC, memo, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import UtilService from "../../../../services/utilService";

import Button from "shared/components/button/Button";
import { MANAGEMENT_LAT_NAV_BTNS } from "../../../../static/MANAGEMENT_LAT_NAV_BTNS";
import './ManagementLatNavbar.scss';

const ManagementLatNavbar: FC<any> = () => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const [section, setSection] = useState<number>(0);
    const { pathname } = useLocation();
    useEffect(() => {
        let activeBtn = MANAGEMENT_LAT_NAV_BTNS.find(btn => btn.path === pathname);
        if (activeBtn) setSection(MANAGEMENT_LAT_NAV_BTNS.indexOf(activeBtn));
    }, [pathname])
    useEffect(() => { }, [collapse])
    return (
        <nav className={`management-lat-nav-bar ${collapse ? "collapse" : ""}`}>
            <ul >
                <li className={`nav-btn f-c`} >
                    <Button onClick={() => setCollapse(!collapse)} className="btn-second-type"  >
                        <img src={UtilService.resolveIconsUrl("menu_white_24dp.svg")} alt="Icono de menu" />
                        <p>Menu</p>
                    </Button>
                </li>
                {MANAGEMENT_LAT_NAV_BTNS.map((button, index) => (
                    <li className={`nav-btn f-c ${section === index ? "active" : ""}`} key={index}>
                        <Button className="btn-second-type" onClick={() => setSection(index)} to={button.path} >
                            <img src={UtilService.resolveIconsUrl(button.img)} alt={button.alt} />
                            <p>{button.btnAction}</p>
                        </Button>
                    </li>
                ))}

            </ul>

        </nav>
    );
}

export default memo(ManagementLatNavbar);