import { useContext } from 'react';
import { AcademiesContext } from './AcademiesContext';




export const useAcademies = () => {

    const { createAcademy,
        inscribeAcademy,
        setAcademiesToContext,
        academiesState,
        selectAcademyToDelete,
        fetchAcademies,
        addAcademy,
        updateAcademy,
        deleteAcademy,
        deleteImage,
        getAcademyByIdFromContext,
        addMembersByDirector,
        updateAvailability
    } = useContext(AcademiesContext);
    const { academies, disciplines, selectedAcademy } = academiesState;
    return {
        deleteAcademy,
        academies,
        disciplines,
        selectedAcademy,
        addAcademy,
        createAcademy,
        deleteImage,
        selectAcademyToDelete,
        fetchAcademies,
        setAcademiesToContext,
        updateAcademy,
        getAcademyByIdFromContext,
        inscribeAcademy,
        addMembersByDirector,
        updateAvailability

    }
}


