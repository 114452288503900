
import { TResponse } from "models/responses/TResponse";
import TDiscipline from "models/types/TDiscipline";
import { httpServer } from "../clients/server";

export default class DisciplineService {
    private static disciplinesController = "/disciplines";

    static getDisciplines(): Promise<TResponse<TDiscipline[]>> {
        return httpServer.get(`${this.disciplinesController}/`)
            .then(({ data }: any): TResponse<TDiscipline[]> => {
                return data as TResponse<TDiscipline[]>;
            })
            .catch(({ response }: any): TResponse<TDiscipline[]> => response.data as TResponse<TDiscipline[]>);
    }

    static createDiscipline(discipline: TDiscipline): Promise<TResponse<TDiscipline>> {
        return httpServer.post(`${this.disciplinesController}/create`, discipline)
            .then(({ data }: any): TResponse<TDiscipline> => {
                return data as TResponse<TDiscipline>;
            })
            .catch(({ response }: any): TResponse<TDiscipline> => response.data as TResponse<TDiscipline>);
    }

    static updateDiscipline(disciplineToUpdate: TDiscipline): Promise<TResponse<TDiscipline>> {
        return httpServer.put(`${this.disciplinesController}/update`, disciplineToUpdate)
            .then(({ data }: any): TResponse<TDiscipline> => {
                return data as TResponse<TDiscipline>;
            })
            .catch(({ response }: any): TResponse<TDiscipline> => response.data as TResponse<TDiscipline>);
    }
    static deleteDiscipline(id: number): Promise<TResponse<boolean>> {
        return httpServer.delete(`${this.disciplinesController}/delete/${id}`)
            .then(({ data }: any): TResponse<boolean> => {
                return data as TResponse<boolean>;
            })
            .catch(({ response }: any): TResponse<boolean> => response.data as TResponse<boolean>);
    }

    static updateDisciplineName(id: number, name: string) {
        // return this.resolveDisciplineServiceResponse(httpServer.patch(`${this.disciplinesController}/update_name?id=${id}&name=${name}`),ERROR_RESPONSES.DISCIPLINE.MODIFY_NAME)
    }

    static updateDisciplineColor(id: number, color: string) {
        // return this.resolveDisciplineServiceResponse(httpServer.patch(`${this.disciplinesController}/update_color?id=${id}&color=${color}`),ERROR_RESPONSES.DISCIPLINE.MODIFY_COLOR)
    }

}