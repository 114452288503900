import TAcademy from 'models/types/TAcademy';
import { TAcademyState } from 'models/states/TAcademyState';
import TDeleteAcademyRequest from 'models/types/requests/TDeleteAcademyRequest';
import { createContext } from 'react';
import TProfessor from 'models/types/TProfessor';
import TStudent from 'models/types/TStudent';

export type AcademiesContextType = {
  academiesState: TAcademyState,
  addAcademy: (value: TAcademy) => void,
  createAcademy: (academyToCreate: TAcademy, logo: File, layoutImg?: File, images?: File[], returnPath?: string) => Promise<boolean>,
  deleteAcademy: (deleteAcademyRequest: TDeleteAcademyRequest) => Promise<void>,
  deleteImage: (id: number, image: string) => Promise<TAcademy | undefined>,
  fetchAcademies: () => Promise<void>,
  getAcademyByIdFromContext: (idAcademy: number) => TAcademy | undefined,
  inscribeAcademy: (academyToCreate: TAcademy, idDirector: number, hash: string, logo: File, layoutImg?: File, images?: File[], returnPath?: string) => Promise<boolean>,
  selectAcademyToDelete: (value: TAcademy | undefined) => void,
  setAcademiesToContext: (value: TAcademy[]) => void,
  updateAcademy: (value: TAcademy, logo?: File, layoutImg?: File, images?: File[]) => Promise<TAcademy | undefined>,
  updateAvailability: (id: number, availability: boolean) => Promise<TAcademy | undefined>,
  addMembersByDirector: (idDirector: number, idAcademy: number, professorsToAdd: TProfessor[], studentsToAdd: TStudent[]) => Promise<boolean>


}


export const AcademiesContext = createContext<AcademiesContextType>({} as AcademiesContextType);