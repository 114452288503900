import { useState } from "react";

export const useForm = <T extends Object>(initialState = {} as T): [T, (value: any, field: string, allow?: boolean) => void, () => void] => {
    const [values, setValues] = useState(initialState);

    const reset = () => {
        setValues(initialState);
    }


    const handleInputChange = (value: any, field: string, allow = true): void => {
        allow && setValues({
            ...values,
            [field]: value
        });

    }
    return [values, handleInputChange, reset];
}