import { TDisciplineState } from 'models/states/TDisciplineState';
import TDiscipline from 'models/types/TDiscipline';

import { createContext } from 'react';

export type DisciplinesContextType = {
    disciplinesState: TDisciplineState,
    fetchDisciplines: () =>  Promise<void>,
    setDisciplinesToContext: (value: TDiscipline[]) => void,
    addDisciplineToContext: (value: TDiscipline) => void,
    deleteDisciplineFromContext: (idDiscipline: number) => void,
    updateDisciplineFromContext: (event: TDiscipline) => void,
    deleteDiscipline: (idDiscipline: number) => Promise<void>,
    createDiscipline: (disciplineToCreate: TDiscipline) => Promise<void>,
    updateDiscipline: (disciplineToUpdate: TDiscipline) => Promise<TDiscipline | undefined>,
    getDisciplineByIdFromContext: (idDiscipline: number) => TDiscipline | undefined,

}


export const DisciplinesContext = createContext<DisciplinesContextType>({} as DisciplinesContextType);