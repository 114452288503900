import { FC } from 'react';
import './Overlay.scss'
const Overlay: FC<any> = ({ children,classname }: any) => {
    return (
        <section className={`overlay ${classname}`}>
            {children}
        </section>
    )
}


export default Overlay;
