import { useContext } from 'react';
import { UsersContext } from './UsersContext';




export const useUsers = () => {

    const {
        addUser,
        sendContactEmail,
        changePassword,
        createProfessor,
        createStudent,
        createUser,
        deleteUser,
        deleteUserByIdFromContext,
        deleteUserFromContext,
        fetchUsers,
        getDirectors,
        getProfessors,
        getStudents,
        getUserByIdFromContext,
        inscribeProfessor,
        inscribeStudent,
        sendActivationLink,
        sendForgotPasswordRequest,
        setUserLogged,
        setUsersToContext,
        signIn,
        signOut,
        signUp,
        updateUser,
        usersState,
        validateActivationLink,
        deleteNotification,
        updateAvailability,
    } = useContext(UsersContext);
    const { users, userLogged } = usersState;

    return {
        addUser,
        updateAvailability,
        changePassword,
        createProfessor,
        createStudent,
        createUser,
        deleteUser,
        deleteUserByIdFromContext,
        deleteUserFromContext,
        fetchUsers,
        getDirectors,
        getProfessors,
        getStudents,
        getUserByIdFromContext,
        inscribeProfessor,
        inscribeStudent,
        sendActivationLink,
        sendForgotPasswordRequest,
        setUserLogged,
        setUsersToContext,
        signIn,
        signOut,
        signUp,
        updateUser,
        userLogged,
        users,
        validateActivationLink,
        deleteNotification,
        sendContactEmail,
    }
}


